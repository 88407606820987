import ToggleIconLinkButton from 'components/ToggleIconLinkButton';
import CAT_CONSTANTS from 'lookup/CatConstants';
import React, { Component } from 'react';
import { Col, Collapse, Row } from 'react-bootstrap';
import { MdAdd, MdRemove } from 'react-icons/md';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getDynamicSwitchGroupDiff } from 'views/Cat/CompareCodexVersion/UpdatedDSGDetails/UpdatedDynamicSwitchGroupDetails.actions';
import { getDynamicSwitchGroup } from 'views/Cat/DynamicSwitchGroupDetails/DynamicSwitchGroupDetails.actions';
import DrugOptionBoxCompact from './DrugOptionBox/DrugOptionBoxCompact';

class SingleDynamicSwitchOptionType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpandedMode: true
    }
  }

  toggleExpandedMode = () => {
    this.setState({
      isExpandedMode: !this.state.isExpandedMode
    })
  }

  // TODO: This should be moved to the parent components where the API calls are actually made he first time
  refreshList = () => {
    if(!this.props.showDelta) {
      this.props.getDynamicSwitchGroup(this.props.dynamicSwitchGroupId);
    } else {
      this.props.getDynamicSwitchGroupDiff(this.props.workingCodexVersionId,
        this.props.dynamicSwitchGroupId);
    }
  }

  render() {
    const { dsoList, itemType, hideInactive, allowEditing, showDelta } = this.props;
    const { isExpandedMode } = this.state;
    return (
      dsoList && dsoList.length > 0 ?
        <div className={"dso-single-option-type"}>
          <Row>
            <Col>
              <div className="dynamic-switch-option-type-label">
                <span>{itemType}</span>
                <span className="pull-right">
                  <ToggleIconLinkButton
                    shouldDisplayFirstTag={isExpandedMode}
                    toggleButton={this.toggleExpandedMode}
                    FirstIcon={MdRemove}
                    firstTag={"Collapse"}
                    SecondIcon={MdAdd}
                    secondTag={"Expand"}
                    linkClassName={"main-button-link"}
                  />
                </span>
              </div>
            </Col>
          </Row>
          <Collapse in={isExpandedMode}>
            <div>
              {
                dsoList.filter(i => (!hideInactive || i.currentDynamicSwitchOption.isActive)
                  && (!showDelta || !i.currentDynamicSwitchOption.isDeleted || i.oldDynamicSwitchOption)).map((dynamicSwitchOption, idx) =>
                  <DrugOptionBoxCompact
                    dynamicSwitchOption={dynamicSwitchOption.currentDynamicSwitchOption}
                    oldDynamicSwitchOption={dynamicSwitchOption.oldDynamicSwitchOption}
                    otherDynamicSwitchOptions={dynamicSwitchOption.otherDynamicSwitchOptions}
                    allowEditing={allowEditing}
                    showDelta={showDelta}
                    workingCodexVersionId={this.props.workingCodexVersionId}
                    refreshList={this.refreshList}
                    dynamicSwitchGroupId={this.props.dynamicSwitchGroupId}
                    hideInactive={hideInactive}
                    id={`option-${idx}`}
                    key={`option-${idx}`}
                   />
                )
              }
            </div>
          </Collapse>
        </div>
        : null
    )
  }
}
const mapStateToProps = (state, ownProps) => {
  const { itemType } = ownProps;
  let dsoList = [];
  if (itemType == CAT_CONSTANTS.dynamicSwitchOptionTypeLabels.TARGET) {
    dsoList = state.dynamicSwitchGroupDetails ? state.dynamicSwitchGroupDetails.targetDrugs : [];
  } else if (itemType == CAT_CONSTANTS.dynamicSwitchOptionTypeLabels.SWITCH) {
    dsoList = state.dynamicSwitchGroupDetails ? state.dynamicSwitchGroupDetails.switchDrugs : [];
  }else{
    dsoList = state.dynamicSwitchGroupDetails ? state.dynamicSwitchGroupDetails.auxiliaryDrugs : [];
  }
  let allowEditing = state.codexVersion?.isWorkingVersionEditable && state.dynamicSwitchGroupDetails?.allowEditing && !state.dynamicSwitchGroupDetails?.showDelta ?
    state.dynamicSwitchGroupDetails.allowEditing : false;
  return {
    allowEditing: allowEditing,
    dsoList: dsoList,
    showDelta: state.dynamicSwitchGroupDetails ? state.dynamicSwitchGroupDetails.showDelta : false,
    workingCodexVersionId: state.codexVersion ? state.codexVersion.codexVersionId : null,
    dynamicSwitchGroupId: state.dynamicSwitchGroupDetails?.dynamicSwitchGroup ? state.dynamicSwitchGroupDetails.dynamicSwitchGroup.dynamicSwitchGroupId : null,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getDynamicSwitchGroupDiff: bindActionCreators(getDynamicSwitchGroupDiff, dispatch),
    getDynamicSwitchGroup: bindActionCreators(getDynamicSwitchGroup, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleDynamicSwitchOptionType);