import React from "react";
import { CSVLink } from "react-csv";
import Button from "react-bootstrap/Button";
import { formatDateForDisplay } from "../../services/MomentUtils";
import { ColumnTypes } from "commons/table/Constants";
import ClientSideReactTable from "commons/table/ClientSideReactTable";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";

const MedicineHistoryModal = (props) => {
  const [medicineHistory, setMedicineHistory] = useState(null);
  const [loading, setLoading] = useState(true);
  const tableRef = useRef("patient_history");
  const csvLinkRef = useRef("csv_downloader_link");
  const [transformedData, setTransformedData] = useState([]);

  let groupedData = [];

  if (medicineHistory) {
    const data = medicineHistory.patientHistory;

    data.forEach((medicine) => {
      medicine.claimDrugName = medicine.claimDrugName || "DRUG NAME N/A";
    });
    let i = 0;
    let j = 0;
    const groupedDataDict = data.reduce((acc, medicine) => {
      const { claimDrugName } = medicine;

      if (!acc[claimDrugName]) {
        acc[claimDrugName] = { items: [] };
      }
      medicine.id = medicine.claimDrugName + "-" + i;
      i++;
      acc[claimDrugName].items.push(medicine);
      return acc;
    }, {});

    Object.keys(groupedDataDict).forEach((medName) => {
      const items = groupedDataDict[medName].items;

      // Sort items by claimFillDate in descending order
      items.sort(
        (a, b) => new Date(b.claimFillDate) - new Date(a.claimFillDate)
      );

      const newestDate = items.length > 0 ? items[0].claimFillDate : "";
      const ind = items.findIndex((elem) => elem.claimFillDate === newestDate);

      items.forEach((medicine) => {
        medicine.claimFillDate = formatDateForDisplay(medicine.claimFillDate);
      });

      const defaultMed = items[ind];
      const claimDrugName = `${defaultMed.claimDrugName} (${items.length})`;
      groupedDataDict[medName]["id"] = j;
      j++;
      // Update groupedDataDict directly
      groupedDataDict[medName].claimDrugName = claimDrugName;
      groupedDataDict[
        medName
      ].claimFillDate = `Last: ${defaultMed.claimFillDate}`;
      groupedDataDict[medName].items = items; // Update the sorted items
    });

    const sortedGroupedData = Object.values(groupedDataDict).sort((a, b) => {
      return a.claimDrugName.localeCompare(b.claimDrugName);
    });

    groupedData = Object.values(sortedGroupedData);
  }

  const fetchMedicineHistory = () => {
    sendAuthenticatedAsyncRequest(
      `/swim/medicine-history?switch_id=${props.switchId}`,
      "GET",
      null,
      (r) => {
        setMedicineHistory(r.data);
        setLoading(false);
      },
      (r) => {
        setLoading(false);
      }
    );
  };

  const getColumns = () => {
    return [
      {
        header: "Drug",
        accessor: "claimDrugName",
        accessorKey: "claimDrugName",
        width: 160,
        enableSorting: true,
        enableColumnFilter: false,
        columnType: ColumnTypes.WrapTextOnNoSpace,
      },
      {
        header: "Filled on",
        accessor: "claimFillDate",
        accessorKey: "claimFillDate",
        width: 60,
        enableSorting: true,
        enableColumnFilter: false,
        columnType: ColumnTypes.Custom,
        customComponent: (r) => {
          return r;
        },
      },
      {
        header: "Normalized Name",
        accessor: "sourceRxnormName",
        accessorKey: "sourceRxnormName",
        width: 250,
        enableSorting: true,
        enableColumnFilter: false,
        columnType: ColumnTypes.WrapTextOnNoSpace,
      },
      {
        header: "Strength",
        accessor: "drugStrength",
        accessorKey: "drugStrength",
        width: 40,
        enableSorting: true,
        enableColumnFilter: false,
        columnType: ColumnTypes.WrapTextOnNoSpace,
      },
      {
        header: "Supply (days)",
        accessor: "sourceDaysSupply",
        accessorKey: "sourceDaysSupply",
        width: 40,
        enableSorting: true,
        enableColumnFilter: false,
        columnType: ColumnTypes.WrapTextOnNoSpace,
      },
      {
        header: "NDC #",
        accessor: "sourceNdcNumber",
        accessorKey: "sourceNdcNumber",
        width: 70,
        enableSorting: true,
        enableColumnFilter: false,
        columnType: ColumnTypes.WrapTextOnNoSpace,
      },
      {
        header: "Controlled Substance",
        accessor: "targetControlledSubstance",
        accessorKey: "targetControlledSubstance",
        width: 80,
        enableSorting: true,
        enableColumnFilter: false,
        columnType: ColumnTypes.WrapTextOnNoSpace,
      },
    ];
  };
  const downloadToCsv = () => {
    const tableInstance = tableRef.current;
    if (!tableInstance) return;

    const tableData = tableInstance.getRowModel().rows;
    const dataToDownload = [];
    const columns = getColumns();

    for (let rowIndex = 0; rowIndex < tableData.length; rowIndex++) {
      const recordToDownload = {};
      const row = tableData[rowIndex].original;

      for (let colIndex = 0; colIndex < columns.length; colIndex++) {
        const columnAccessor = columns[colIndex].accessor;
        recordToDownload[columns[colIndex].header] = row[columnAccessor] || "";
      }
      dataToDownload.push(recordToDownload);
      if (row.items && row.items.length > 0) {
        row.items.forEach((subRow) => {
          const subRecordToDownload = {};
          const subRowData = subRow; // Access the data directly from subRow

          for (
            let subColIndex = 0;
            subColIndex < columns.length;
            subColIndex++
          ) {
            const subColumnAccessor = columns[subColIndex].accessor;
            subRecordToDownload[columns[subColIndex].header] =
              subRowData[subColumnAccessor] || ""; // Handle missing values with an empty string
          }
          dataToDownload.push(subRecordToDownload);
        });
      }
    }

    console.log("This is the data to download", dataToDownload);
    setTransformedData([...dataToDownload]); // Update the transformed data for CSV download
  };

  useEffect(() => {
    if (transformedData.length > 0 && csvLinkRef.current) {
      csvLinkRef.current.link.click(); // Trigger CSV download after state update
    }
  }, [transformedData]);

  useEffect(() => {
    fetchMedicineHistory();
  }, []);
  return (
    <>
      <>
        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <Button
            onClick={() => downloadToCsv()}
            variant="link"
            className="pull-right"
          >
            Export to CSV
          </Button>
          <CSVLink
            ref={csvLinkRef}
            data={transformedData}
            filename={`switch_${props.switchId}_medical_history.csv`}
            className="hidden"
            target="_blank"
          />
        </div>
        <ClientSideReactTable
          ref={tableRef}
          columnDefination={getColumns()}
          data={groupedData}
          totalRecords={groupedData ? groupedData.length : 0}
          enableMultiRowSelection={false}
          enableExpansion={true}
          pivot={"items"}
          overrideRowId={"id"}
          addRowSelection={false}
          defaultPageSize={10}
          loading={loading}
        />
      </>
    </>
  );
};

export default MedicineHistoryModal;
