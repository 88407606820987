import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";
import { scheduleCycle, resetSuccess } from "./CycleScheduler.action";
import Container from "react-bootstrap/Container";
import DateTimePicker from "react-datetime-picker";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormLabel from "react-bootstrap/FormLabel";
import FormGroup from "react-bootstrap/FormGroup";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Alert from "react-bootstrap/Alert";
import SwitchCycleList from "views/Switch/SwitchCycleList/SwitchCycleList";
import ReactBadge from "react-bootstrap/Badge";
import Select from "react-select";
import { showNotification } from "services/NotificationService";
import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import MessageBanner from "../../../components/MessageBanner.js";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

const Tip = (props) => (
  <div style={{ textAlign: "right", color: "#000000", fontSize: "0.9em" }}>
    <em>{props.children}</em>
  </div>
);

const customStyles = {
  multiValue: (base) => ({
    background: "#007BBB",
    display: "inline-flex",
    color: "white",
    borderRadius: "11px",
    alignItems: "center",
  }),
  multiValueLabel: (base) => ({
    fontSize: "13px",
    color: "white",
    padding: "2px 4px 2px 4px",
  }),
};

class CycleScheduler extends React.Component {
  state = {
    description: "",
    scheduledDate: new Date(),
    error: "",
    clientOptions: [], // all clients + ALL
    submittingRequest: "",
    isSuccess: false,
    customerId: "",
    eventRequestCompleted: false,
    switchList: [],
    clicked: false,
    disableAction: false,
    noOfSelectedSwitches: 0,
    fetchSwitchList: false,
    selectedClientNameAbbreviation: [],
    selectedFilters: {
      clientNameAbbreviation: [],
      customerNameAbbreviation: [],
    },
    filterSelectMap: {},
    originalFilterState: {},
    isShowBioSimilarToggle: false,
  };

  setBioSimilarSwitchesToggle = (value) => {
    this.setState({ isShowBioSimilarToggle: value });
  };

  componentDidUpdate(prevProps) {
    const {
      description,
      error,
      submittingRequest,
      eventRequestCompleted,
      isSuccess,
      switchList,
      disableAction,
    } = this.props;

    if (
      error !== prevProps.error ||
      description !== prevProps.description ||
      submittingRequest !== prevProps.submittingRequest ||
      eventRequestCompleted !== prevProps.eventRequestCompleted ||
      isSuccess !== prevProps.isSuccess ||
      switchList !== prevProps.switchList
    ) {
      this.setState((prevState) => ({
        error,
        description,
        submittingRequest,
        isSuccess,
        eventRequestCompleted,
        switchList: switchList ? switchList : prevState.switchList,
        noOfSelectedSwitches: switchList
          ? switchList.length
          : prevState.switchList.length,
      }));
    }

    if (isSuccess && isSuccess !== prevProps.isSuccess) {
      showNotification({
        title: "Cycle Created!",
        message: "Cycle is scheduled successfully.",
        position: "bl",
        type: "success",
      });
      this.props.resetSuccess();
      this.props.history.push("/scheduler/cycle/list");
    }
  }

  fetchData = () => {
    sendAuthenticatedAsyncRequest(
      `/swim/batch/get-client-customer-filters`,
      "GET",
      null,
      (r) => {
        if (r.data.filters !== null) {
          this.updateFilters(r.data.filters);
        }
      },
      (r) => {
        showNotification({
          title: "Error fetching Filter Values!",
          message: "Something went Wrong.",
          position: "tr",
          type: "error",
        });
      }
    );
  };

  updateFilters(tempFilters) {
    let tempFilterSelectMap = {};
    Object.keys(tempFilters).forEach(
      (colKey, i) =>
        (tempFilterSelectMap[colKey] = tempFilters[colKey].map((row, index) => {
          return { value: row["id"], label: row["value"] };
        }))
    );
    this.setState({ filterSelectMap: tempFilterSelectMap });
    this.setState({ originalFilterState: tempFilterSelectMap });
  }

  componentDidMount() {
    this.fetchData();
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value, error: "" });
  };

  validateForm(scheduledDate, switchList, description) {
    var currentDate = new Date();
    if (scheduledDate < currentDate)
      return "Date and Time should be greater than current Time";
    if (switchList.length == 0) return "Switch list can not be empty";
    if (description && description.length > 100)
      return "Description can not exceed 100 characters in length";
    return null;
  }

  scheduleCycle = (e) => {
    const { scheduledDate, description, switchList, isShowBioSimilarToggle } =
      this.state;
    const clientList = this.state.selectedFilters.clientNameAbbreviation.reduce(
      (result, entry) => {
        return result.concat(entry.value);
      },
      []
    );
    var validationMessage = this.validateForm(
      scheduledDate,
      switchList,
      description
    );
    if (validationMessage === null) {
      const cycleDto = {
        scheduledDate: scheduledDate,
        description: description,
        wfItemUuids: switchList,
        clientList: clientList,
      };
      this.props.scheduleCycle(cycleDto);
    } else {
      this.setState({ error: validationMessage });
    }
  };

  setSelectedSwitches = (selected) => {
    this.setState({
      switchList: selected,
      noOfSelectedSwitches: selected.length,
    });
  };

  switchIdsSectionClicked = () => {
    this.setState({
      clicked: !this.state.clicked,
    });
  };

  removeSwitch = (switchId) => {
    let switchList = this.state.switchList;
    let index = switchList.indexOf(switchId);
    if (index > -1) {
      switchList = [...switchList];
      switchList.splice(index, 1);
    }
    let noOfSelectedSwitches = switchList.length;
    this.setState({
      switchList,
      noOfSelectedSwitches,
    });
  };

  handleClientChange = (option) => {
    const updatedClientList = option !== null ? option : [];

    this.setState({
      switchList: [],
      noOfSelectedSwitches: 0,
      selectedClientNameAbbreviation: [],
      fetchSwitchList: false,
    });
    this.setState((prevState) => ({
      selectedFilters: {
        ...prevState.selectedFilters,
        clientNameAbbreviation: updatedClientList,
      },
    }));
  };

  handleCustomerChange = (option) => {
    let extractedSelectedClients = [];
    if (option != null) {
      const appliedCustomerFilters = option.label;
      const extractedSelectedCustomers = this.state.filterSelectMap[
        "customerClientMapping"
      ].filter((e) => appliedCustomerFilters === e.value);
      const extractedClients = extractedSelectedCustomers.reduce(
        (result, entry) => {
          return result.concat(entry.label);
        },
        []
      );

      extractedSelectedClients =
        this.state.selectedFilters.clientNameAbbreviation.filter((item) =>
          new Set(extractedClients).has(item.label)
        );
      const clientOptions = this.state.originalFilterState[
        "clientNameAbbreviation"
      ].filter((e) => extractedClients.includes(e.label));
      this.setState((prevState) => ({
        filterSelectMap: {
          ...prevState.filterSelectMap,
          clientNameAbbreviation: clientOptions,
        },
      }));
    }

    // Set customer and client lists
    const updatedCustomerList = option !== null ? [option] : [];
    this.setState({
      selectedFilters: {
        clientNameAbbreviation: extractedSelectedClients,
        customerNameAbbreviation: updatedCustomerList,
      },
      switchList: [],
      noOfSelectedSwitches: 0,
      selectedClientNameAbbreviation: [],
      fetchSwitchList: false,
    });
  };

  addClientsToCustomers(
    customerListArray = this.state.selectedFilters.customerNameAbbreviation,
    clientListArray = this.state.selectedFilters.clientNameAbbreviation
  ) {
    let extractedSelectedClients = clientListArray;

    if (clientListArray.length === 0) {
      // Get possible list of clients for customer selected
      const appliedCustomerFilters = customerListArray.map(
        (customer) => customer.label
      );
      const extractedSelectedCustomers = this.state.filterSelectMap[
        "customerClientMapping"
      ].filter((e) => appliedCustomerFilters.includes(e.value));
      const extractedClients = extractedSelectedCustomers.reduce(
        (result, entry) => {
          return result.concat(entry.label);
        },
        []
      );
      extractedSelectedClients =
        this.state.originalFilterState.clientNameAbbreviation.filter((item) =>
          new Set(extractedClients).has(item.label)
        );
    }

    return extractedSelectedClients;
  }

  searchSwitches = () => {
    let reducedClientListArray = this.addClientsToCustomers();
    reducedClientListArray = reducedClientListArray.reduce((result, entry) => {
      return result.concat(entry.label);
    }, []);

    this.setState({ selectedClientNameAbbreviation: reducedClientListArray });
    this.setState({ fetchSwitchList: true });
  };

  onDateChange = (scheduledDate) => this.setState({ scheduledDate, error: "" });

  render() {
    const {
      scheduledDate,
      description,
      error,
      submittingRequest,
      eventRequestCompleted,
      switchList,
      fetchSwitchList,
      disableAction,
      noOfSelectedSwitches,
      selectedClientNameAbbreviation,
      filterSelectMap,
      selectedFilters,
    } = this.state;
    if (eventRequestCompleted && error === "")
      return <Redirect to={`/cycle/list`} />;
    return (
      <Container fluid className="top-spacer">
        <Row>
          <Col md={{ span: 15 }}>
            <MessageBanner
              message={
                "You cannot schedule a new Cycle as one is already being processed"
              }
              isDisplayMessage={disableAction}
              icon={faExclamationCircle}
            />
            <div>
              <Card variant="primary">
                <Card.Header>
                  <Card.Title className="text-center">
                    <h4>Scheduler</h4>
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  {error ? <Alert variant="danger">{error}</Alert> : null}
                  {submittingRequest ? (
                    <Alert variant="primary">{submittingRequest}</Alert>
                  ) : null}
                  <Row>
                    <Col md={12}>
                      <Tip>Enter Date & Time according to your Time Zone</Tip>
                    </Col>
                    <Col md={12}>
                      <FormGroup className="tiny-top-spacer" as={Row}>
                        <Col md={3}>
                          <FormLabel>Date & Time</FormLabel>
                        </Col>
                        <Col md={9}>
                          <DateTimePicker
                            onChange={this.onDateChange}
                            value={scheduledDate}
                            disabled={disableAction}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="tiny-top-spacer" as={Row}>
                        <Col md={3}>
                          <FormLabel>Description </FormLabel>
                        </Col>
                        <Col md={9}>
                          <FormControl
                            type="text"
                            name="description"
                            value={description}
                            placeholder="Enter Description (optional)"
                            onChange={this.handleChange}
                            disabled={disableAction}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="tiny-top-spacer" as={Row}>
                        <Col md={3}>
                          <FormLabel>Customer </FormLabel>
                        </Col>
                        <Col md={9}>
                          <Select
                            options={
                              Object.keys(filterSelectMap).includes(
                                "customerNameAbbreviation"
                              )
                                ? filterSelectMap["customerNameAbbreviation"]
                                : []
                            }
                            value={selectedFilters.customerNameAbbreviation}
                            onChange={(event) =>
                              this.handleCustomerChange(event)
                            }
                            placeholder={
                              selectedFilters.clientNameAbbreviation.length ===
                              0
                                ? "Enter Customer (Mandatory)"
                                : "Enter Customer (Optional)"
                            }
                            closeMenuOnSelect={false}
                            styles={customStyles}
                          ></Select>
                        </Col>
                      </FormGroup>
                      <FormGroup className="tiny-top-spacer" as={Row}>
                        <Col md={3}>
                          <FormLabel>Client </FormLabel>
                        </Col>
                        <Col md={9}>
                          <Select
                            options={
                              Object.keys(filterSelectMap).includes(
                                "clientNameAbbreviation"
                              )
                                ? filterSelectMap["clientNameAbbreviation"]
                                : []
                            }
                            value={selectedFilters.clientNameAbbreviation}
                            onChange={(event) => this.handleClientChange(event)}
                            isMulti
                            placeholder={
                              selectedFilters.customerNameAbbreviation
                                .length === 0
                                ? "Enter Client (Mandatory)"
                                : "Enter Client (Optional)"
                            }
                            closeMenuOnSelect={false}
                            styles={customStyles}
                          ></Select>
                        </Col>
                      </FormGroup>
                      <FormGroup className="tiny-top-spacer" as={Row}>
                        <Col md={3}>
                          <FormLabel>No. of Selected Switches </FormLabel>
                        </Col>
                        <Col md={9}>
                          <ReactBadge
                            id="no-of-selected-switches"
                            variant="success"
                            pill
                            style={{ margin: "-1px 5px", fontSize: "100%" }}
                          >
                            {noOfSelectedSwitches ? noOfSelectedSwitches : 0}
                          </ReactBadge>
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col
                      md={{ span: 3, offset: 9 }}
                      className="d-grid gap-2 mt-2"
                    >
                      {fetchSwitchList === true ? (
                        <Button
                          className="btn-block btn-text-white"
                          variant="success"
                          onClick={this.scheduleCycle}
                          disabled={
                            submittingRequest ||
                            disableAction ||
                            switchList.length === 0
                          }
                        >
                          Schedule Cycle
                        </Button>
                      ) : (
                        <Button
                          onClick={() => this.searchSwitches()}
                          disabled={
                            submittingRequest ||
                            disableAction ||
                            (selectedFilters.clientNameAbbreviation.length ==
                              0 &&
                              selectedFilters.customerNameAbbreviation.length ==
                                0)
                          }
                        >
                          Search
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </div>
          </Col>
          {disableAction ||
          (fetchSwitchList === false &&
            selectedClientNameAbbreviation.length === 0) ? null : (
            <SwitchCycleList
              selectedClientNameAbbreviation={selectedClientNameAbbreviation}
              selected={switchList}
              setSelectedSwitches={this.setSelectedSwitches}
              moveToTheListView={this.state.clicked}
              bioSimilarSwitchesToggle={this.state.isShowBioSimilarToggle}
              setBioSimilarSwitchesToggle={this.setBioSimilarSwitchesToggle}
            />
          )}
        </Row>
      </Container>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    scheduledDate: state.cycleScheduler.scheduledDate,
    error: state.cycleScheduler.error,
    description: state.cycleScheduler.description,
    submittingRequest: state.cycleScheduler.submittingRequest,
    isSuccess: state.cycleScheduler.isSuccess,
    switchList: state.cycleScheduler.switchList,
    disableAction: state.cycleScheduler.disableAction,
    eventRequestCompleted: state.cycleScheduler.eventRequestCompleted,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    scheduleCycle: bindActionCreators(scheduleCycle, dispatch),
    resetSuccess: bindActionCreators(resetSuccess, dispatch),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CycleScheduler)
);
