import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  updateInterventionList,
  updateIndexOnList,
  resetError,
} from "./SwitchList.actions";
import { withRouter } from "react-router-dom";
import ResetListState from "components/ResetListState";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { DEFAULT_SWIM_SORT, SWIM_FILTER } from "lookup/SwimConstants";
import SwitchListCommon from "./SwitchListCommon";
import { COLUMN } from "./CreateColumnListForSwitchList";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Form } from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import { isLoggedInUserBioSimilarUser } from "services/AWUtilityService";
import { updateIsShowBioSimilarToggle } from "./SwitchList.actions";
import { isLoggedInUserAWAdmin } from "services/AWUtilityService";

const DEFAULT_PAGE_SIZE = 15;

const COLUMN_LIST = [
  COLUMN.customerNameAbbreviation,
  COLUMN.clientNameAbbreviation,
  COLUMN.switchNumber,
  COLUMN.state,
  COLUMN.status,
  COLUMN.prescriberCallCounter,
  COLUMN.daysAfterLastModifiedDate,
  COLUMN.lastModifiedDate,
  COLUMN.pharmacyName,
  COLUMN.pharmacyCallCounter,
  COLUMN.isPickupConfirmed,
  COLUMN.memberNumberWithoutCustId,
  COLUMN.memberNumber,
  COLUMN.memberCallCounter,
  COLUMN.effectiveDate,
  COLUMN.projectedSavings,
  COLUMN.prescriberName,
  COLUMN.prescriberPhone,
  COLUMN.prescriberFax,
  COLUMN.pharmacyPhone,
  COLUMN.prescriberNPI,
  COLUMN.pharmacyFax,
  COLUMN.pharmacyNPI,
];

class SwitchList extends React.Component {
  state = {
    isInitialLoadComplete: false,
  };

  resetList = () => {
    const { pageSize, resized, expanded } = this.props.listState;
    let { isShowBioSimilarToggle } = this.props;
    let bioSimilarToggle = isShowBioSimilarToggle;
    if (isLoggedInUserBioSimilarUser() && !isLoggedInUserAWAdmin()) {
      bioSimilarToggle = true;
      this.props.updateInterventionList(
        pageSize,
        0,
        DEFAULT_SWIM_SORT,
        [
          ...SWIM_FILTER.defualt,
          { id: "priorityAgent", value: true },
          { id: "customerNameAbbreviation", value: "WMT" },
          { id: "clientNameAbbreviation", value: "WMT" },
        ],
        resized,
        expanded
      );
    } else {
      bioSimilarToggle = false;
      this.props.updateInterventionList(
        pageSize,
        0,
        DEFAULT_SWIM_SORT,
        [...SWIM_FILTER.defualt, { id: "priorityAgent", value: false }],
        resized,
        expanded
      );
    }
    this.props.updateIsShowBioSimilarToggle(bioSimilarToggle);
  };

  componentDidMount() {
    const { isShowBioSimilarToggle } = this.props;
    if (isShowBioSimilarToggle === undefined) {
      let bioSimilarToggle = isShowBioSimilarToggle;
      const isBioSimilarUser = isLoggedInUserBioSimilarUser();
      if (isBioSimilarUser && !isLoggedInUserAWAdmin()) {
        bioSimilarToggle = true;
        this.setState({ isInitialLoadComplete: true });
      } else {
        bioSimilarToggle = false;
        this.setState({ isInitialLoadComplete: true });
      }
      this.props.updateIsShowBioSimilarToggle(bioSimilarToggle);
    } else {
      this.setState({ isInitialLoadComplete: true });
    }
  }

  handleBioSimilarToggle = (e) => {
    const { filtered } = this.props.listState;
    let newFiltered = [...SWIM_FILTER.defualt];

    if (e.target.checked) {
      newFiltered = newFiltered.filter(
        (element) =>
          ![
            "priorityAgent",
            "customerNameAbbreviation",
            "clientNameAbbreviation",
          ].includes(element.id)
      );
      newFiltered.push(
        { id: "priorityAgent", value: true },
        { id: "customerNameAbbreviation", value: "WMT" },
        { id: "clientNameAbbreviation", value: "WMT" }
      );
      if (JSON.stringify(newFiltered) !== JSON.stringify(filtered)) {
        this.props.updateInterventionList(
          this.props.listState.pageSize,
          this.props.listState.page,
          this.props.listState.sorted,
          newFiltered,
          this.props.listState.resized,
          this.props.listState.expanded
        );
      }
    } else {
      newFiltered = newFiltered.filter(
        (element) =>
          ![
            "priorityAgent",
            "customerNameAbbreviation",
            "clientNameAbbreviation",
          ].includes(element.id)
      );
      newFiltered.push({ id: "priorityAgent", value: false });
      if (JSON.stringify(newFiltered) !== JSON.stringify(filtered)) {
        this.props.updateInterventionList(
          this.props.listState.pageSize,
          this.props.listState.page,
          this.props.listState.sorted,
          newFiltered,
          this.props.listState.resized,
          this.props.listState.expanded
        );
      }
    }
    this.props.updateIsShowBioSimilarToggle(e.target.checked);
  };

  render() {
    return (
      <Container className="list-container" fluid>
        <Row>
          <Col md={12}>
            <div
              className="codex-version-display-bar"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span style={{ float: "left" }}>
                Total Switches: <strong>{this.props.totalElements}</strong>
              </span>
              <strong style={{ textAlign: "center" }}>Switch List</strong>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  float: "right",
                }}
              >
                {isLoggedInUserBioSimilarUser() && (
                  <span
                    className="tiny-bottom-spacer"
                    style={{ marginRight: "15px" }}
                  >
                    <OverlayTrigger
                      overlay={
                        <Tooltip>
                          Toggling this ON will show Bio Similar Switches
                        </Tooltip>
                      }
                    >
                      <Form.Check
                        type="switch"
                        className="aw-bio-similar-callgroups-toggle"
                        id="toggleBioSimilarRef"
                        label={`Bio Similar Switches`}
                        onChange={(e) => this.handleBioSimilarToggle(e)}
                        checked={this.props.isShowBioSimilarToggle} // Make sure this reflects the state
                      />
                    </OverlayTrigger>
                  </span>
                )}
                <ResetListState onReset={this.resetList} />
              </div>
            </div>
          </Col>
        </Row>
        <SwitchListCommon
          updateInterventionList={this.props.updateInterventionList}
          updateIndexOnList={this.props.updateIndexOnList}
          resetError={this.props.resetError}
          data={this.props.data}
          pages={this.props.pages}
          totalElements={this.props.totalElements}
          loading={this.props.loading}
          listState={this.props.listState}
          isError={this.props.isError}
          columnList={COLUMN_LIST}
          isShowBioSimilarToggle={this.props.isShowBioSimilarToggle}
          isSortFilterEnabledForCust={true}
          isInitialLoadComplete={this.state.isInitialLoadComplete}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  let listState = state.switchList.listState;
  if (!listState) {
    listState = {
      page: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      sorted: DEFAULT_SWIM_SORT,
      filtered:
        isLoggedInUserBioSimilarUser() && !isLoggedInUserAWAdmin()
          ? [...SWIM_FILTER.defualt, { id: "priorityAgent", value: true }]
          : [...SWIM_FILTER.defualt, { id: "priorityAgent", value: false }],
      resized: [],
      expanded: {},
    };
  }
  return {
    data: state.switchList.data !== undefined ? state.switchList.data : [],
    pages: state.switchList.pages ? state.switchList.pages : 0,
    totalElements:
      state.switchList.totalElements !== undefined
        ? state.switchList.totalElements
        : "Loading ...",
    loading: state.switchList.loading,
    listState: listState,
    isError: state.switchList.isError,
    isShowBioSimilarToggle: state.switchList.isShowBioSimilar ?? undefined,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateInterventionList: bindActionCreators(
      updateInterventionList,
      dispatch
    ),
    updateIndexOnList: bindActionCreators(updateIndexOnList, dispatch),
    resetError: bindActionCreators(resetError, dispatch),
    updateIsShowBioSimilarToggle: bindActionCreators(
      updateIsShowBioSimilarToggle,
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SwitchList));
