export const DEFAULT_FILTER = "DEFAULT_FILTERS";
export const DEFAULT_BULK_ACTION_LIST_SORT = [
  { id: "lastModifiedDate", desc: true, isDefault: true },
];
export const DEFAULT_BULK_ACTION_FILTER = [
  {
    id: "state",
    value: ["ACTIVE", "AWAITING_RESPONSE", "HOLD", "FILL_PENDING"],
    isdefault: true,
  },
  {
    id: "status",
    value: [
      "ACTIVE_DELAYED",
      "GENERATED_PDF",
      "DELIVERED_PRESCRIBER_FAX",
      "FAILED_PRESCRIBER_FAX",
      "ERROR_PRESCRIBER_FAX",
      "ERROR_PUTTING_ON_HOLD",
      "ON_HOLD",
      "SUBMITTED_VIA_EHR",
      "ERROR_SUBMITTED_VIA_EHR",
      "QUEUED_PDF_GENERATION"
    ],
    isdefault: true,
  },
];

export const DEFAULT_BULK_ACTION_CLIENT_PAUSE_FILTER = [
  {
    id: "state",
    value: ["ACTIVE", "AWAITING_RESPONSE", "HOLD", "FILL_PENDING"],
    isdefault: true,
  },
  {
    id: "status",
    value: [
      "ACTIVE_DELAYED",
      "GENERATED_PDF",
      "DELIVERED_PRESCRIBER_FAX",
      "FAILED_PRESCRIBER_FAX",
      "ERROR_PRESCRIBER_FAX",
      "FAILED_PRESCRIBER_FAX",
      "ERROR_PUTTING_ON_HOLD",
      "RECEIVED_PRESCRIBER_FAX",
      "FORWARDED_PHARMACY_FAX",
      "ERROR_PHARMACY_FAX",
      "FAILED_PHARMACY_FAX",
      "MISSING_PRESCRIBER_CONTACT_INFO"
    ],
    isdefault: true,
  },
];

export const STATUSES_FOR_BULK_ACTION_STATE_SELECT = [
  { key: "ACTIVE_DELAYED", value: "ACTIVE_DELAYED" },
  { key: "GENERATED_PDF", value: "GENERATED_PDF" },
  { key: "DELIVERED_PRESCRIBER_FAX", value: "DELIVERED_PRESCRIBER_FAX" },
  { key: "FAILED_PRESCRIBER_FAX", value: "FAILED_PRESCRIBER_FAX" },
  { key: "ERROR_PRESCRIBER_FAX", value: "ERROR_PRESCRIBER_FAX" },
  { key: "ERROR_PUTTING_ON_HOLD", value: "ERROR_PUTTING_ON_HOLD" },
  { key: "RECEIVED_PRESCRIBER_FAX", value: "RECEIVED_PRESCRIBER_FAX" },
  { key: "FORWARDED_PHARMACY_FAX", value: "FORWARDED_PHARMACY_FAX" },
  { key: "ERROR_PHARMACY_FAX", value: "ERROR_PHARMACY_FAX" },
  { key: "FAILED_PHARMACY_FAX", value: "FAILED_PHARMACY_FAX" },
  { key: "ON_HOLD", value: "ON_HOLD" },
  { key: "SUBMITTED_VIA_EHR", value: "SUBMITTED_VIA_EHR" },
  { key: "ERROR_SUBMITTED_VIA_EHR", value: "ERROR_SUBMITTED_VIA_EHR" },
  { key: "RECEIVED_PHARMACY_FAX", value: "RECEIVED_PHARMACY_FAX" },
  { key: "ERROR_PUTTING_ON_HOLD", value: "ERROR_PUTTING_ON_HOLD" },
  {key : "MISSING_PRESCRIBER_CONTACT_INFO", value: "MISSING_PRESCRIBER_CONTACT_INFO"},
  { key: "QUEUED_PDF_GENERATION", value: "QUEUED_PDF_GENERATION" }
];

export const DEFAULT_BULK_ACTION_HOLD_FILTER = [
  {
    id: "state",
    value: ["HOLD"],
    isdefault: true,
  },
  {
    id: "status",
    value: ["ON_HOLD", "ERROR_PUTTING_ON_HOLD"],
    isdefault: true,
  },
];
export const DEFAULT_BULK_ACTION_STATE_FILTER = [
  {
    id: "state",
    value: ["ACTIVE", "AWAITING_RESPONSE", "HOLD", "FILL_PENDING"],
    isdefault: true,
  },
];

export const DEFAULT_BULK_ACTION_STATUS_FILTER = [
  {
    id: "status",
    value: [
      "ACTIVE_DELAYED",
      "GENERATED_PDF",
      "DELIVERED_PRESCRIBER_FAX",
      "FAILED_PRESCRIBER_FAX",
      "ERROR_PRESCRIBER_FAX",
      "HOLD",
    ],
    isdefault: true,
  },
];

export const BULK_ACTION_FILTER = {
  default: DEFAULT_BULK_ACTION_FILTER,
  defaultStateFrontEnd: DEFAULT_BULK_ACTION_STATE_FILTER,
  defaultStatusFrontEnd: DEFAULT_BULK_ACTION_STATUS_FILTER,
};

export const bulk_action_statuses = [
  { key: "", value: "ALL_FILTERS" },
  { key: "PENDING", value: "PENDING" },
  { key: "SCHEDULED", value: "SCHEDULED" },
  { key: "ACTIVE", value: "ACTIVE" },
  { key: "COMPLETED", value: "COMPLETED" },
];

export const bulk_action_type_options = [
  { label: "ALL", value: "ALL" },
  { label: "Client Pause", value: "Client Pause" },
  { label: "Close Duplicate", value: "Close Duplicate" },
  { label: "Close No Response", value: "Close No Response" },
  { label: "Close Formulary/Plan Issue", value: "Close Formulary/Plan Issue" },
  { label: "Hold", value: "Hold" },
  { label: "Hold Resume", value: "Hold Resume" },
  { label: "Fax Resend", value: "Fax Resend" },
  { label: "Regenerate PDF's", value: "Regenerate PDF's" },
  { label: "Update Status to Delivered", value: "Update Status to Delivered" },
];

export const drop_down_options = [
  { label: "Batch", value: "Batch" },
  { label: "Cycle", value: "Cycle" },
  { label: "Client Pause", value: "Client Pause" },
  { label: "Close Duplicate", value: "Close Duplicate" },
  { label: "Close No Response", value: "Close No Response" },
  { label: "Close Formulary/Plan Issue", value: "Close Formulary/Plan Issue" },
  { label: "Hold", value: "Hold" },
  { label: "Hold Resume", value: "Hold Resume" },
  { label: "Fax Resend", value: "Fax Resend" },
  { label: "Regenerate PDF's", value: "Regenerate PDF's" },
  { label: "Update Status to Delivered", value: "Update Status to Delivered" }
];

export const bulk_action_item_status = [
  { id: "SCHEDULED", name: "SCHEDULED" },
  { id: "ACTIVE", name: "ACTIVE" },
  { id: "CANCELLED", name: "CANCELLED" },
  { id: "COMPLETED", name: "COMPLETED" },
  { id: "FAILED", name: "FAILED" },
];

export const DEFAULT_BULK_LIST_SORTED = [
  { id: "dateScheduled", desc: true, isDefault: true },
];
export const DEFAULT_BULK_LIST_FILTERS = [
  { id: "status", value: "", isdefault: true },
];

export const DEFAULT_PAGE_SIZE = 15;

export const DEFAULT_BULK_ACTION_MISSING_PRESCRIBER_FILTER = [
  {
    id: "state",
    value: ["ACTIVE"],
    isdefault: true,
  },
  {
    id: "status",
    value: ["MISSING_PRESCRIBER_CONTACT_INFO"],
    isdefault: true,
  },
];
