import React from "react";
import Card from "react-bootstrap/Card";
import { CSVLink } from "react-csv";
import DismissableModal from "../DismissableModal";
import PatientMedicineList from "./PatientMedicineList";
import { Button } from "react-bootstrap";
import { formatDateForDisplay } from "../../services/MomentUtils";
import { ColumnTypes } from "commons/table/Constants";
import ClientSideReactTable from "commons/table/ClientSideReactTable";

const styles = {
  card: {
    padding: 10,
  },
  cardHeader: { paddingLeft: 0, backgroundColor: "white", letterSpacing: 2 },
  cardBody: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    alignItems: "center",
  },
};

class PatientHistory extends React.Component {
  constructor(props) {
    super(props);
    // Defining refs with React.createRef()
    this.tableRef = React.createRef();
    this.csvLinkRef = React.createRef();

    this.state = {
      transformedData: [],
    };
  }

  getColumns = () => {
    return [
      {
        header: "Drug",
        accessor: "claimDrugName",
        accessorKey: "claimDrugName",
        width: 160,
        enableSorting: true,
        enableColumnFilter: false,
        columnType: ColumnTypes.WrapTextOnNoSpace,
      },
      {
        header: "Filled on",
        accessor: "claimFillDate",
        accessorKey: "claimFillDate",
        width: 60,
        enableSorting: true,
        enableColumnFilter: false,
        columnType: ColumnTypes.Custom,
        customComponent: (r) => {
          return r;
        },
      },
      {
        header: "Normalized Name",
        accessor: "sourceRxnormName",
        accessorKey: "sourceRxnormName",
        width: 250,
        enableSorting: true,
        enableColumnFilter: false,
        columnType: ColumnTypes.WrapTextOnNoSpace,
      },
      {
        header: "Strength",
        accessor: "drugStrength",
        accessorKey: "drugStrength",
        width: 40,
        enableSorting: true,
        enableColumnFilter: false,
        columnType: ColumnTypes.WrapTextOnNoSpace,
      },
      {
        header: "Supply (days)",
        accessor: "sourceDaysSupply",
        accessorKey: "sourceDaysSupply",
        width: 40,
        enableSorting: true,
        enableColumnFilter: false,
        columnType: ColumnTypes.WrapTextOnNoSpace,
      },
      {
        header: "NDC #",
        accessor: "sourceNdcNumber",
        accessorKey: "sourceNdcNumber",
        width: 70,
        enableSorting: true,
        enableColumnFilter: false,
        columnType: ColumnTypes.WrapTextOnNoSpace,
      },
      {
        header: "Controlled Substance",
        accessor: "targetControlledSubstance",
        accessorKey: "targetControlledSubstance",
        width: 80,
        enableSorting: true,
        enableColumnFilter: false,
        columnType: ColumnTypes.WrapTextOnNoSpace,
      },
    ];
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.transformedData.length > 0 &&
      prevState.transformedData !== this.state.transformedData
    ) {
      if (this.csvLinkRef.current) {
        this.csvLinkRef.current.link.click(); // Trigger CSV download after state update
      }
    }
  }

  downloadToCsv = () => {
    console.log("heree in download csv");
    const tableInstance = this.tableRef.current;
    if (!tableInstance) return;

    const tableData = tableInstance.getRowModel().rows;
    console.log("this is table data", tableData);
    const dataToDownload = [];
    const columns = this.getColumns();

    for (let rowIndex = 0; rowIndex < tableData.length; rowIndex++) {
      const recordToDownload = {};
      const row = tableData[rowIndex].original;

      for (let colIndex = 0; colIndex < columns.length; colIndex++) {
        const columnAccessor = columns[colIndex].accessor;
        recordToDownload[columns[colIndex].header] = row[columnAccessor] || "";
      }
      dataToDownload.push(recordToDownload);

      if (row.items && row.items.length > 0) {
        row.items.forEach((subRow) => {
          const subRecordToDownload = {};
          const subRowData = subRow;

          for (
            let subColIndex = 0;
            subColIndex < columns.length;
            subColIndex++
          ) {
            const subColumnAccessor = columns[subColIndex].accessor;
            subRecordToDownload[columns[subColIndex].header] =
              subRowData[subColumnAccessor] || "";
          }
          dataToDownload.push(subRecordToDownload);
        });
      }
    }

    this.setState({ transformedData: [...dataToDownload] });
  };

  render() {
    let data = JSON.parse(JSON.stringify(this.props.patientHistory));
    console.log("This is the patient history", this.props.patientHistory);

    data = data.slice().sort(function (a, b) {
      let keyA = new Date(a.claimFillDate),
        keyB = new Date(b.claimFillDate);
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });

    data.forEach((medicine) => {
      medicine.claimDrugName = medicine.claimDrugName || "DRUG NAME N/A";
    });
    var groupedDataDict = {};
    var i = 0;
    data.forEach((medicine) => {
      if (!(medicine.claimDrugName in groupedDataDict)) {
        groupedDataDict[medicine.claimDrugName] = {};
        groupedDataDict[medicine.claimDrugName]["items"] = [];
      }
      medicine.id = medicine.claimDrugName + "-" + i;
      i++;
      groupedDataDict[medicine.claimDrugName].items.push(medicine);
    });
    var j = 0;
    Object.keys(groupedDataDict).forEach((medName) => {
      let dates = groupedDataDict[medName]["items"].map((medicines) => {
        return medicines["claimFillDate"];
      });
      let newestDate = dates.length > 0 ? dates.sort()[dates.length - 1] : "";
      let ind = 0;
      if (newestDate.length > 0) {
        ind = dates.findIndex((elem) => elem === newestDate);
      }
      groupedDataDict[medName]["id"] = j;
      j++;
      groupedDataDict[medName]["items"].forEach(
        (medicines) =>
          (medicines["claimFillDate"] = formatDateForDisplay(
            medicines["claimFillDate"]
          ))
      );
      const defaultMed = groupedDataDict[medName]["items"][ind];
      groupedDataDict[medName]["claimDrugName"] =
        defaultMed["claimDrugName"] +
        " (" +
        groupedDataDict[medName]["items"].length.toString() +
        ")";
      groupedDataDict[medName]["claimFillDate"] = "Last: ".concat(
        defaultMed["claimFillDate"]
      );
    });
    const groupedData = Object.keys(groupedDataDict).map(
      (medName) => groupedDataDict[medName]
    );

    return (
      <Card style={styles.card}>
        <Card.Header style={styles.cardHeader}>MEDICINE HISTORY</Card.Header>
        <Card.Body style={styles.cardBody}>
          <PatientMedicineList
            history={data}
            listGroupStyle={this.props.listGroupStyle}
          />
          <DismissableModal
            customModalStyle={{ height: "100%" }}
            openModalButtonText="Full History"
            openModalButtonBlock={true}
            openModalButtonStyle="link"
            openModalButtonDisabled={data ? false : true}
            title="Member History"
            modalClassName="super-wide-modal"
          >
            <div style={{ display: "flex", flexDirection: "row-reverse" }}>
              <Button
                onClick={() => this.downloadToCsv()}
                variant="link"
                className="pull-right"
              >
                Export to CSV
              </Button>
              <CSVLink
                ref={this.csvLinkRef}
                data={this.state.transformedData}
                filename={`switch_${this.props.switchId}_medical_history.csv`}
                className="hidden"
                target="_blank"
              />
            </div>
            <ClientSideReactTable
              ref={this.tableRef}
              columnDefination={this.getColumns()}
              data={groupedData}
              overrideRowId="id"
              totalRecords={groupedData ? groupedData.length : 0}
              enableMultiRowSelection={false}
              enableExpansion={true}
              pivot={"items"}
              addRowSelection={false}
              defaultPageSize={10}
            />
          </DismissableModal>
        </Card.Body>
      </Card>
    );
  }
}

export default PatientHistory;
