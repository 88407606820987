import React, { useState } from 'react';
import { Dropdown, Form } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { DescriptionDeltaField } from "./DeltaFieldUtils";

const ITEM_TYPES = ["target", "switch", "both","auxiliary"];
const EXTRA_SPACES = "  ";
const DynamicSwitchOptionItemTypeField = ({
  dynamicSwitchOptionId = "",
  isEditMode = false,
  error = "",
  warning = "",
  savedValue = "",
  updatedValue = "",
  handleChange = () => {},
  handleBlur = () => {},
  isDrugTypeBoth = false,
  isHorizontalView = false,
  isRequired = false,
  itemTypesToDisplay = ITEM_TYPES,
  predecessorValue = "",
  isUpdated = false,
}) => {
  const onChange = (e, value) => {
    e.target.value = value;
    handleChange(e);
    handleBlur(e);
  };

  const id = `itemType${dynamicSwitchOptionId}`;
  const Label = (
    <Form.Label
      className={
        "rm-small-top-label" + (isRequired ? " rm-required-field" : "")
      }
    >
      {"Drug Type"}
    </Form.Label>
  );
  const DropdownComplete =
    itemTypesToDisplay.length > 0 ? (
      <Dropdown>
        <Dropdown.Toggle
          id="dropdown-basic"
          className="text-capitalize field-value dropdown-custom-button"
          disabled={isDrugTypeBoth}
        >
          {savedValue == updatedValue && isDrugTypeBoth
            ? "both" + EXTRA_SPACES
            : updatedValue
            ? updatedValue + EXTRA_SPACES
            : "None"}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {itemTypesToDisplay.map((x) => {
            return (
              <Dropdown.Item
                id={"itemType"}
                key={x}
                value={x}
                onClick={(e) => onChange(e, x)}
                eventKey={x}
                className="text-capitalize field-value space-pre-wrap"
              >
                {x + EXTRA_SPACES}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    ) : null;
  return !isEditMode ? (
    predecessorValue ? (
      <>
        <DescriptionDeltaField
          isUpdated={isUpdated}
          oldField={predecessorValue}
          newField={savedValue}
          heading={Label}
          className={"text-capitalize"}
        />
      </>
    ) : null
  ) : (
    <>
      <Form.Group as={isHorizontalView ? Row : undefined}>
        {isHorizontalView ? <Col md={3}>{Label}</Col> : Label}
        {isHorizontalView ? (
          <Col md={9}>
            {DropdownComplete}
            <Form.Text
              id={id + "-error-msg"}
              className="text-small text-danger space-pre-wrap"
            >
              {error}
            </Form.Text>
            <Form.Text //this is warning but id is error-msg
              id={id + "-error-msg"}
              className="text-small text-warning space-pre-wrap"
            >
              {warning}
            </Form.Text>
          </Col>
        ) : (
          DropdownComplete
        )}
        {isHorizontalView ? null : (
          <Form.Text id={id + "-error-msg"} className="text-small text-danger">
            {error}
          </Form.Text>
        )}
      </Form.Group>
    </>
  );
};
export default React.memo(DynamicSwitchOptionItemTypeField);
