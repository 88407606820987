import { ERROR, IN_PROGRESS, RESET, SUCCESS } from "lookup/ResponseCodes";
import { getLoggedInUser } from "services/AuthService";
import { mouldDynamicSwitchOptions } from "./DynamicSwitchGroupUtils";

function extractDsgInfo(editableBy) {
  const loggedInUser = getLoggedInUser();
  const isWorkingDsgEditable = editableBy ? true : false;
  let allowEditing =
    (isWorkingDsgEditable && editableBy.userId === loggedInUser.uuid) ||
    (isWorkingDsgEditable &&
      loggedInUser.authorities.includes("ADMIN_RELEASE_LOCK"));

  return {
    isWorkingDsgEditable,
    allowEditing,
  };
}

export function Reducer(previousState, action) {
  let newState = Object.assign({}, previousState);

  switch (action.type) {
    case "FETCH_DYNAMIC_SWITCH_GROUP_REQUEST":
    case "DELTA_DSG_DETAIL_REQUEST":
      newState = Object.assign({}, previousState, {
        dynamicSwitchGroup: null,
        oldDynamicSwitchGroup: null,
        fetchDsgRequestStatus: IN_PROGRESS,
      });
      break;
    case "FETCH_DYNAMIC_SWITCH_GROUP_SUCCESS":
      var { targetDrugs, switchDrugs, auxiliaryDrugs } =
        mouldDynamicSwitchOptions(action.payload?.dynamicSwitchOptions, false);
      var { isWorkingDsgEditable, allowEditing } = extractDsgInfo(
        action.payload.editableBy
      );
      newState = Object.assign({}, previousState, {
        targetDrugs,
        switchDrugs,
        auxiliaryDrugs,
        dynamicSwitchGroup: action.payload,
        fetchDsgRequestStatus: SUCCESS,
        editableBy: action.payload.editableBy,
        isWorkingDsgEditable,
        showDelta: false,
        allowEditing,
      });
      break;
    case "DELTA_DSG_DETAIL_SUCCESS":
      var { targetDrugs, switchDrugs, auxiliaryDrugs } =
        mouldDynamicSwitchOptions(
          action.payload.currentAndOldDynamicSwitchOptionList,
          true
        );
      newState = Object.assign({}, previousState, {
        targetDrugs,
        switchDrugs,
        auxiliaryDrugs,
        dynamicSwitchGroup: action.payload.currentDynamicSwitchGroup,
        fetchDsgRequestStatus: SUCCESS,
        oldDynamicSwitchGroup: action.payload.oldDynamicSwitchGroup,
        showDelta: true,
      });
      break;
    case "FETCH_DYNAMIC_SWITCH_GROUP_FAILURE":
    case "DELTA_DSG_DETAIL_FAILURE":
      newState = Object.assign({}, previousState, {
        dynamicSwitchGroup: null,
        oldDynamicSwitchGroup: null,
        fetchDsgRequestStatus: ERROR,
        editableBy: null,
        isWorkingDsgEditable: null,
      });
      break;
    case "UPDATE_DYNAMIC_SWITCH_GROUP_REQUEST":
      newState = Object.assign({}, previousState, {
        updateDsgRequestStatus: IN_PROGRESS,
      });
      break;
    case "UPDATE_DYNAMIC_SWITCH_GROUP_SUCCESS":
      var { isWorkingDsgEditable, allowEditing } = extractDsgInfo(
        action.payload.editableBy
      );
      newState = Object.assign({}, previousState, {
        dynamicSwitchGroup: action.payload,
        updateDsgRequestStatus: SUCCESS,
        editableBy: action.payload.editableBy,
        isWorkingDsgEditable,
        allowEditing,
      });
      break;
    case "UPDATE_DYNAMIC_SWITCH_GROUP_FAILURE":
      newState = Object.assign({}, previousState, {
        updateDsgRequestStatus: ERROR,
      });
      break;
    case "RESET_DSG_EVENT_REQUEST":
      newState = Object.assign({}, previousState, {
        updateDsgRequestStatus: RESET,
        fetchDsgRequestStatus: RESET,
      });
      break;
    case "SAVE_WORKING_DSG_INFO":
      var { isWorkingDsgEditable, allowEditing } = extractDsgInfo(
        action.payload.editableBy
      );
      newState = Object.assign({}, previousState, {
        isWorkingDsgEditable,
        editableBy: action.payload.editableBy,
        allowEditing,
      });
      break;
    default:
      break;
  }

  return newState;
}
