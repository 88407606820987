import React, { useRef, useState } from "react";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DismissableModal from "components/DismissableModal";
import { FormSelect } from "react-bootstrap";
import * as Yup from "yup";
import { getErrorMessage } from "services/UtilityService";
import { Formik } from "formik";
import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
import { showNotification } from "services/NotificationService";
import { withRouter } from "react-router-dom";
import {
  TEMPLATE_TYPES_BIO_SIMILAR,
  TEMPLATE_TYPES_NON_BIO_SIMILAR,
} from "lookup/SwimConstants";
import { isLoggedInUserBioSimilarUser } from "services/AWUtilityService";

const ChangeSwitchTemplate = (props) => {
  const [rirSwitchId] = useState(props.rirSwitchId);
  const [selectedTemplate] = useState(props.selectedTemplate);
  const { contextIsRaw, history, disableButton, showBioSimilarOptions } = props;
  const title = "Change Template";
  const modalRef = useRef("ChangeSwitchTemplateModal");
  const changeTemplateSchema = Yup.object().shape(
    {
      templateIndicator: Yup.number().test(
        "is-different",
        "Selected template type must be different from original",
        (value) => value !== selectedTemplate
      ),
      note: Yup.string()
        .max(4096, "Max Length: 4096")
        .nullable(true)
        .required("Required!"),
    },
    [["note"]]
  );

  const submitForm = React.useCallback((originalValues, setSubmitting) => {
    sendAuthenticatedAsyncRequest(
      `/swim/change-template/${rirSwitchId}`,
      "PUT",
      { ...originalValues },
      (r) => {
        showNotification({
          title: `Template Change Successful`,
          message: `Switch template changed!`,
          position: "tr",
          type: "success",
        });
        setSubmitting(false);
        modalRef.current.handleClose();

        if (contextIsRaw) {
          history.goBack();
        } else {
          window.location.reload();
        }
      },
      (r) => {
        showNotification({
          title: `Unable to Change Templates!`,
          message:
            getErrorMessage(r.data) || getErrorMessage("SOMETHING_WENT_WRONG"),
          position: "tr",
          type: "error",
        });
        setSubmitting(false);
      }
    );
  });

  return (
    <DismissableModal
      key={title}
      ref={modalRef}
      openModalButtonText="Change Template"
      openModalButtonBlock={true}
      openModalButtonStyle="warning"
      openModalButtonDisabled={disableButton}
      title={title}
      size="lg"
      hideFooter={true}
    >
      <Container>
        <Formik
          enableReinitialize
          initialValues={{
            templateIndicator: selectedTemplate ?? "",
            note: "",
          }}
          onSubmit={React.useCallback((values, { setSubmitting }) => {
            submitForm(values, setSubmitting);
          })}
          validationSchema={changeTemplateSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={12}>
                    <Form.Group>
                      <Form.Label>Template Type</Form.Label>
                      <FormSelect
                        value={values.templateIndicator}
                        onChange={(e) =>
                          setFieldValue("templateIndicator", e.target.value)
                        }
                        name="templateIndicator"
                        onBlur={handleBlur}
                      >
                        {isLoggedInUserBioSimilarUser() && showBioSimilarOptions
                          ? TEMPLATE_TYPES_BIO_SIMILAR.map((option) => (
                              <option key={option.value} value={option.value}>
                                {selectedTemplate === option.value
                                  ? `${option.key} (Current)`
                                  : option.key}
                              </option>
                            ))
                          : TEMPLATE_TYPES_NON_BIO_SIMILAR.map((option) => (
                              <option key={option.value} value={option.value}>
                                {selectedTemplate === option.value
                                  ? `${option.key} (Current)`
                                  : option.key}
                              </option>
                            ))}
                      </FormSelect>
                      <Form.Text className="text-small text-danger">
                        {errors.templateIndicator &&
                          touched.templateIndicator &&
                          errors.templateIndicator}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Form.Group>
                      <Form.Label className={"rm-required-field"}>
                        Notes
                      </Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.note}
                        name="note"
                        as="textarea"
                        data-testid="input-create-activity-notes"
                      />
                      <Form.Text className="text-small text-danger">
                        {errors.note && touched.note && errors.note}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span>
                      *Changing the template will unlink the current fax
                      template and restart the switch process workflow*
                    </span>
                  </Col>
                </Row>
                <Row className="top-spacer">
                  <Col md={4} className="bottom-spacer">
                    <Button
                      type="submit"
                      variant="success"
                      block="true"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Creating ... " : "Create"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </>
          )}
        </Formik>
      </Container>
    </DismissableModal>
  );
};

export default withRouter(ChangeSwitchTemplate);
