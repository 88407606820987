import CAT_CONSTANTS from "lookup/CatConstants";

function mouldDynamicSwitchOptions(dynamicSwitchOptions, isDelta = false) {
  dynamicSwitchOptions = fixDsoList(dynamicSwitchOptions, isDelta);
  let switchDrugs = dynamicSwitchOptions
    ? dynamicSwitchOptions.filter(
        (dso) =>
          dso.currentDynamicSwitchOption.itemType ===
          CAT_CONSTANTS.dynamicSwitchOptionTypes.SWITCH
      )
    : [];
  let targetDrugs = dynamicSwitchOptions
    ? dynamicSwitchOptions.filter(
        (dso) =>
          dso.currentDynamicSwitchOption.itemType ===
          CAT_CONSTANTS.dynamicSwitchOptionTypes.TARGET
      )
    : [];
  let auxiliaryDrugs = dynamicSwitchOptions
  ? dynamicSwitchOptions.filter(
      (dso) =>
        dso.currentDynamicSwitchOption.itemType ===
        CAT_CONSTANTS.dynamicSwitchOptionTypes.AUXILIARY
    )
  : [];
  targetDrugs = getCollapsedVersionOfDrugs(targetDrugs, switchDrugs, true);
  switchDrugs = getCollapsedVersionOfDrugs(switchDrugs, targetDrugs, false);
  targetDrugs.sort((a, b) => {
    return a.currentDynamicSwitchOption.medName.localeCompare(
      b.currentDynamicSwitchOption.medName
    );
  });
  switchDrugs.sort((a, b) => {
    return a.currentDynamicSwitchOption.medName.localeCompare(
      b.currentDynamicSwitchOption.medName
    );
  });
  auxiliaryDrugs.sort((a, b) => {
    return a.currentDynamicSwitchOption.medName.localeCompare(
      b.currentDynamicSwitchOption.medName
    );
  });
  return { switchDrugs, targetDrugs,auxiliaryDrugs };
}

function fixDsoList(dsoList, isDelta) {
  if (isDelta) return dsoList;
  return dsoList.map((x) => {
    return { currentDynamicSwitchOption: x, oldDynamicSwitchOption: null };
  });
}

function checkIfSameDrugExistsInOtherItemType(dso, otherItemTypeDsoList) {
  let otherDsoIndex = otherItemTypeDsoList.findIndex(
    (otherDso) =>
      otherDso.currentDynamicSwitchOption.gpiCode ==
        dso.currentDynamicSwitchOption.gpiCode &&
      otherDso.currentDynamicSwitchOption.brandGenType ==
        dso.currentDynamicSwitchOption.brandGenType &&
      otherDso.currentDynamicSwitchOption.medName ==
        dso.currentDynamicSwitchOption.medName &&
      otherDso.currentDynamicSwitchOption.routeOfAdministration ==
        dso.currentDynamicSwitchOption.routeOfAdministration &&
      otherDso.currentDynamicSwitchOption.itemType !=
        dso.currentDynamicSwitchOption.itemType
  );
  return otherDsoIndex;
}

function getCollapsedVersionOfDrugs(drugs, otherItemTypeDrugs, isTarget) {
  let uniqueDrugs = [];
  if (drugs.length > 0) {
    uniqueDrugs.push({ ...drugs[0], otherDynamicSwitchOptions: [] });
  }
  let otherDsoItemTypeIndex =
    drugs.length > 0
      ? checkIfSameDrugExistsInOtherItemType(uniqueDrugs[0], otherItemTypeDrugs)
      : -1;
  if (isTarget) {
    if (otherDsoItemTypeIndex > -1) {
      uniqueDrugs[0].currentDynamicSwitchOption.isDrugTypeBoth = true;
      otherItemTypeDrugs[
        otherDsoItemTypeIndex
      ].currentDynamicSwitchOption.isDrugTypeBoth = true;
    }
  }
  for (let i = 1; i < drugs.length; i++) {
    let index = uniqueDrugs.findIndex(
      ({ currentDynamicSwitchOption }) =>
        currentDynamicSwitchOption.gpiCode ==
          drugs[i].currentDynamicSwitchOption.gpiCode &&
        currentDynamicSwitchOption.brandGenType ==
          drugs[i].currentDynamicSwitchOption.brandGenType &&
        currentDynamicSwitchOption.medFormCode ==
          drugs[i].currentDynamicSwitchOption.medFormCode &&
        currentDynamicSwitchOption.routeOfAdministration ==
          drugs[i].currentDynamicSwitchOption.routeOfAdministration &&
        currentDynamicSwitchOption.isActive ==
          drugs[i].currentDynamicSwitchOption.isActive &&
        currentDynamicSwitchOption.isDeleted ==
          drugs[i].currentDynamicSwitchOption.isDeleted
    );
    if (isTarget) {
      otherDsoItemTypeIndex = checkIfSameDrugExistsInOtherItemType(
        drugs[i],
        otherItemTypeDrugs
      );
      if (otherDsoItemTypeIndex > -1) {
        drugs[i].currentDynamicSwitchOption.isDrugTypeBoth = true;
        otherItemTypeDrugs[
          otherDsoItemTypeIndex
        ].currentDynamicSwitchOption.isDrugTypeBoth = true;
      }
    }
    if (index < 0) {
      uniqueDrugs.push({ ...drugs[i], otherDynamicSwitchOptions: [] });
    } else {
      uniqueDrugs = addLowestCostAsMainTarget(uniqueDrugs, drugs[i], index);
    }
  }
  uniqueDrugs =
    updateSelectionStatusAndIsActiveOfMultiTargetsStrengths(uniqueDrugs);
  return uniqueDrugs;
}

function updateSelectionStatusAndIsActiveOfMultiTargetsStrengths(uniqueDrugs) {
  /*Check if any DSO is linked to other DSOs where some of the matching strengths are unselected for certain DSOs and selected for the others.
    In this scenario, it will be marked with a special status to ensure that it can be displayed appropriately. 
    Its isActive status will also be updated based on this calculation, since it may have been overwritten in some earlier process
    when the joining together of drugs was happening*/
  uniqueDrugs.forEach((drug) => {
    drug.currentDynamicSwitchOption.dynamicSwitchItems.forEach(
      (dynamicSwitchItem) => {
        let strengthToCheck = dynamicSwitchItem.itemStrength;
        let unselectedMatchingStrengthsCount = 0;
        let totalMatchingStrengthsCount = 0;
        let isAnyStrengthActive = false;
        drug.otherDynamicSwitchOptions.forEach((otherDso) => {
          otherDso.currentDynamicSwitchOption.dynamicSwitchItems.forEach(
            (otherDsi) => {
              if (
                otherDsi.itemStrength === strengthToCheck &&
                !otherDsi.isDeleted
              ) {
                totalMatchingStrengthsCount += 1;
                if (!otherDsi.isActive) {
                  unselectedMatchingStrengthsCount += 1;
                } else {
                  isAnyStrengthActive = true;
                }
              }
            }
          );
        });
        if (unselectedMatchingStrengthsCount > 0) {
          dynamicSwitchItem.isActive = isAnyStrengthActive;
        }
        dynamicSwitchItem.selectionStatus =
          unselectedMatchingStrengthsCount > 0 &&
          unselectedMatchingStrengthsCount !== totalMatchingStrengthsCount
            ? CAT_CONSTANTS.dynamicSwitchItemSelectionStatus.PARTIALLY_SELECTED
            : CAT_CONSTANTS.dynamicSwitchItemSelectionStatus
                .FULLY_SELECTED_OR_UNSELECTED;
      }
    );
  });
  return uniqueDrugs;
}

function addLowestCostAsMainTarget(uniqueDrugs, newDrug, index) {
  let shouldAddToExisting = isDso1HighPriority(
    uniqueDrugs[index].currentDynamicSwitchOption,
    newDrug.currentDynamicSwitchOption
  );
  let alternativeDrugNames = getAlternativeDrugNames(
    uniqueDrugs[index],
    newDrug,
    shouldAddToExisting
  );
  let strengths = addStrengths(
    uniqueDrugs[index],
    newDrug,
    shouldAddToExisting
  );
  strengths = strengths.sort((a, b) => {
    let num = (a.itemStrength + a.itemUnitOfMeasure).localeCompare(
      b.itemStrength + b.itemUnitOfMeasure,
      "en",
      { numeric: true }
    );
    return num;
  });
  let otherDSOs = getOtherDSO(uniqueDrugs[index], newDrug, shouldAddToExisting);
  if (shouldAddToExisting) {
    uniqueDrugs[index].currentDynamicSwitchOption.alternativeDrugNames =
      alternativeDrugNames;
    uniqueDrugs[index].currentDynamicSwitchOption.dynamicSwitchItems =
      strengths;
    uniqueDrugs[index].otherDynamicSwitchOptions = otherDSOs;
  } else {
    // replace the drug
    newDrug.currentDynamicSwitchOption.alternativeDrugNames =
      alternativeDrugNames;
    newDrug.currentDynamicSwitchOption.dynamicSwitchItems = strengths;
    newDrug.otherDynamicSwitchOptions = otherDSOs;
    uniqueDrugs[index] = newDrug;
  }
  return uniqueDrugs;
}

function getAlternativeDrugNames(
  uniqueDrug = {},
  newDrug = {},
  shouldAddToExisting
) {
  let alternativeDrugNames =
    uniqueDrug.currentDynamicSwitchOption.alternativeDrugNames;
  if (alternativeDrugNames) {
    alternativeDrugNames += shouldAddToExisting
      ? `, ${newDrug.currentDynamicSwitchOption.medName}`
      : `, ${uniqueDrug.currentDynamicSwitchOption.medName}`;
  } else {
    alternativeDrugNames = shouldAddToExisting
      ? newDrug.currentDynamicSwitchOption.medName
      : uniqueDrug.currentDynamicSwitchOption.medName;
  }
  alternativeDrugNames += newDrug.currentDynamicSwitchOption
    .alternativeDrugNames
    ? `, ${newDrug.currentDynamicSwitchOption.alternativeDrugNames}`
    : "";
  return alternativeDrugNames;
}

function addStrengths(uniqueDrug = {}, newDrug = {}, shouldAddToExisting) {
  let existingStrengths = [],
    newStrengths = [];
  if (shouldAddToExisting) {
    existingStrengths =
      uniqueDrug.currentDynamicSwitchOption.dynamicSwitchItems;
    newStrengths = newDrug.currentDynamicSwitchOption.dynamicSwitchItems;
  } else {
    existingStrengths = newDrug.currentDynamicSwitchOption.dynamicSwitchItems;
    newStrengths = uniqueDrug.currentDynamicSwitchOption.dynamicSwitchItems;
  }
  for (let i = 0; i < newStrengths.length; i++) {
    if (
      existingStrengths.find(
        (x) =>
          x.itemStrength == newStrengths[i].itemStrength &&
          x.itemUnitOfMeasure == newStrengths[i].itemUnitOfMeasure
      )
    ) {
      // Do nothing
    } else {
      existingStrengths.push({
        ...newStrengths[i],
        isNotMainTargetStrength: true,
      });
    }
  }
  return existingStrengths;
}

function getOtherDSO(uniqueDrug = {}, newDrug = {}, shouldAddToExisting) {
  let otherDSOs = uniqueDrug.otherDynamicSwitchOptions;
  if (shouldAddToExisting) {
    let addAtIndex = sortedIndex(otherDSOs, newDrug.currentDynamicSwitchOption);
    otherDSOs.splice(addAtIndex, 0, newDrug);
  } else {
    delete uniqueDrug.currentDynamicSwitchOption.alternativeDrugNames;
    delete uniqueDrug.otherDynamicSwitchOptions;
    let addAtIndex = sortedIndex(
      otherDSOs,
      uniqueDrug.currentDynamicSwitchOption
    );
    otherDSOs.splice(addAtIndex, 0, uniqueDrug);
  }
  return otherDSOs;
}

function sortedIndex(array, value) {
  var low = 0,
    high = array.length;

  while (low < high) {
    var mid = (low + high) >>> 1;
    if (isDso1HighPriority(array[mid].currentDynamicSwitchOption, value))
      low = mid + 1;
    else high = mid;
  }
  return low;
}

function isDso1HighPriority(dso1, dso2) {
  return dso2.estimatedCost < dso1.estimatedCost ||
    dso2.medName.localeCompare(dso1.medName) < 0
    ? false
    : true;
}

export { mouldDynamicSwitchOptions };
