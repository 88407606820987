export const allowedSwitchStatusesKeywordsForLinking = [
  "Expired",
  "Delivered_Prescriber_Fax",
  "No_prescriber_response",
  "No_prescriber_contact",
  "Ninty_day_close_missing_data",
];
export const GET_MEMBER_SWITCH_LIST_REQUEST = "GET_MEMBER_SWITCH_LIST_REQUEST";
export const GET_MEMBER_SWITCH_LIST_SUCCESS = "GET_MEMBER_SWITCH_LIST_SUCCESS";
export const GET_MEMBER_SWITCH_LIST_FAILURE = "GET_MEMBER_SWITCH_LIST_FAILURE";
export const RESET_MEMBER_SWITCH_LIST = "RESET_MEMBER_SWITCH_LIST";
