import CAT_CONSTANTS from 'lookup/CatConstants';
import React, { Component } from 'react';
import DynamicSwitchOptionHeading from './DynamicSwitchOptionHeading';
import SingleDynamicSwitchOptionType from './SingleDynamicSwitchOptionType';

class DynamicSwitchOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSort: CAT_CONSTANTS.dynamicSwitchGroupListSortValues[0],
      hideInactive: true && !props.showDelta,
    }
  }
  
  handleSortChange = (sortOption) => {
    this.setState({ currentSort: sortOption });
  }

  handleInactiveFilter = (event) => {
    this.setState({ hideInactive: !this.state.hideInactive })
  }

  render() {
    const { currentSort, hideInactive } = this.state;
    const { showDelta } = this.props;
    const sortClassName = currentSort.value === CAT_CONSTANTS.dynamicSwitchGroupListSortConsts.SWITCH_TO_TARGET ? "dso-sort-class" : "";
    return (
      <>
        <DynamicSwitchOptionHeading
          handleSortChange={this.handleSortChange}
          handleInactiveFilter={showDelta ? null : this.handleInactiveFilter}
          hideInactive={hideInactive}
          currentSort={currentSort} />
        <div className={'dynamic-switch-options-list ' + sortClassName}>
          <SingleDynamicSwitchOptionType
            itemType={CAT_CONSTANTS.dynamicSwitchOptionTypeLabels.TARGET}
            hideInactive={hideInactive} />
          <SingleDynamicSwitchOptionType
            itemType={CAT_CONSTANTS.dynamicSwitchOptionTypeLabels.SWITCH}
            hideInactive={hideInactive} />
          <SingleDynamicSwitchOptionType
            itemType={CAT_CONSTANTS.dynamicSwitchOptionTypeLabels.AUXILIARY_DRUGS}
            hideInactive={hideInactive} />
        </div>
      </>
    )
  }
}
export default DynamicSwitchOptions;