import React from "react";
import { withRouter } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  SWITCH_NUMBER_STRING,
  SWITCH_NUMBER_OP,
  SWITCH_STATE_OP,
} from "lookup/GlobalConstants";
import {
  SWIM_STATES_FOR_SELECT,
  DEFAULT_SWIM_SWITCH_SORT,
  SWIM_SWITCH_FILTER,
  DEFAULT_FILTER,
} from "lookup/SwimConstants";
import { showNotification } from "services/NotificationService";
import ServerSideReactTable from "commons/table/ServerSideReactTable";
import { createColumnListForSwimSwitchList } from "./CreateColumnListForSwimSwitchList";

const Tip = (props) => (
  <div style={{ textAlign: "center", color: "#888", fontSize: "0.9em" }}>
    <em>{props.children}</em>
  </div>
);

const Resize = (col) => (
  <div style={{ textAlign: "center", fontSize: "0.9em" }}>{col}</div>
);

const DEFAULT_PAGE_SIZE = 15;

// updateData
// selectable
class SwimSwitchListCommon extends React.Component {
  state = {
    columnList: createColumnListForSwimSwitchList(
      this.props.columnList,
      this.props.isSortFilterEnabledForCust
    ),
  };

  updateData = (listOptions, preventUpdate) => {
    if (!preventUpdate) {
      listOptions.filtered = listOptions.filtered.filter((element) => {
        // Check if the value is a non-empty string or array
        if (typeof element.value === "string" || Array.isArray(element.value)) {
          return element.value.length > 0;
        }
        // Keep the element if the value is a boolean (either true or false)
        if (typeof element.value === "boolean") {
          return true; // Keep both true and false boolean values
        }
        // For other types, ensure the value is not undefined or null
        return element.value !== undefined && element.value !== null;
      });

      if (listOptions.sorted !== DEFAULT_SWIM_SWITCH_SORT)
        listOptions.sorted.forEach((element, i) => {
          element.isDefault = false;
        });

      const index = listOptions.filtered.findIndex(
        (element) => element.id === "switchNumber"
      );
      if (index !== -1) {
        listOptions.filtered[index].operator = SWITCH_NUMBER_OP;
        listOptions.filtered[index].id = SWITCH_NUMBER_STRING;
      }

      const index_type = listOptions.filtered.findIndex(
        (element) => element.id === "type"
      );
      if (index_type !== -1) {
        listOptions.filtered[index_type].value = listOptions.filtered[
          index_type
        ].value.map((value) => (value.name ? value.name : value));
      }
      if (this.props.isShowBioSimilarToggle) {
        const clientNameFilterExists = listOptions.filtered.some(
          (f) => f.id === "clientNameAbbreviation"
        );
        const customerNameFilterExists = listOptions.filtered.some(
          (f) => f.id === "customerNameAbbreviation"
        );

        if (!clientNameFilterExists) {
          listOptions.filtered.push({
            id: "clientNameAbbreviation",
            value: "WMT",
          });
        }

        if (!customerNameFilterExists) {
          listOptions.filtered.push({
            id: "customerNameAbbreviation",
            value: "WMT",
          });
        }
      }
      if (this.props.updateData) {
        this.props.updateData(listOptions);
      } else {
        this.props.updateInterventionList(
          listOptions.pageSize,
          listOptions.page,
          listOptions.sorted,
          listOptions.filtered,
          listOptions.resized,
          listOptions.expanded
        );
      }
    } else {
      this.setState({ listState: listOptions });
    }
  };

  componentDidMount() {
    if (this.props.isInitialLoadComplete) {
      this.updateData(this.props.listState);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isInitialLoadComplete !== this.props.isInitialLoadComplete) {
      this.updateData(this.props.listState);
    }
    if (this.props.isError && this.props.isError !== prevProps.isError) {
      showNotification({
        title: "Error Loading Data!",
        message: "You have entered invalid information.",
        position: "bl",
        type: "error",
      });
      this.props.resetError();
    }
  }

  render() {
    const { data, pages, loading, totalElements, listState } = this.props;
    const index = listState.filtered.findIndex(
      (element) => element.id === SWITCH_NUMBER_STRING
    );
    if (index !== -1) listState.filtered[index].id = "switchNumber";

    const onUpdateTable = (filterValues, sortBy, pageSize, pageNo) => {
      const listState = {
        page: pageNo,
        pageSize: pageSize,
        sorted: sortBy || [],
        filtered: filterValues,
        resized: [],
        expanded: {},
      };
      this.updateData(listState);
    };

    const statusFilterMap = [];
    SWIM_STATES_FOR_SELECT.forEach((state, i) =>
      statusFilterMap.push({ id: state.key, name: state.value })
    );

    return (
      <Row>
        <Col md={12}>
          <ServerSideReactTable
            columnDefination={this.state.columnList}
            data={data}
            currentPage={listState.page}
            sorted={listState.sorted}
            filters={listState.filtered}
            totalPages={pages}
            totalRecords={totalElements == "loading" ? 0 : totalElements}
            loading={loading}
            overrideRowId={"wfItemId"}
            pageSizeOptions={[5, 10, 15, 20, 30, 50]}
            defaultPageSize={DEFAULT_PAGE_SIZE}
            onUpdateTableData={onUpdateTable}
            enableMultiRowSelection={!!this.props.toggleSelection}
            addRowSelection={!!this.props.toggleSelection}
            onRowSelect={this.props.toggleSelection}
            toggleAllRowsSelect={this.props.isSelectAll}
            globalSelected={this.props.selected ? this.props.selected : []}
            resetTableSelection={
              this.props.resetTableSelection
                ? this.props.resetTableSelection
                : null
            }
          />
          <Tip>
            Tip: Hold shift when sorting to multi-sort and disable sort!
          </Tip>
        </Col>
      </Row>
    );
  }
}

export default withRouter(SwimSwitchListCommon);
