export function Reducer(previousState, action) {
  let newState = Object.assign({}, previousState);

  switch (action.type) {
    case "UPDATE_CALL_GROUP_LIST_SUCCESS":
      newState = Object.assign({}, previousState, {
        data: action.payload.records,
        pages: action.payload.totalPages,
        pageSize: action.payload.pageSize,
        totalElements: action.payload.totalElements,
        loading: false,
        isError: false,
      });
      newState.listState.page = action.payload.page;
      break;
    case "UPDATE_CALL_GROUP_LIST_REQUEST":
      newState = Object.assign({}, previousState, {
        totalElements: "Loading ...",
        loading: true,
        isError: false,
      });
      break;
    case "UPDATE_CALL_GROUP_LIST_STATE":
      newState = Object.assign({}, previousState, {
        listState: action.payload.listState,
      });
      break;
    case "UPDATE_CALL_GROUP_INDEX_ON_LIST":
      newState = Object.assign({}, previousState, {
        indexOnList: action.indexOnList,
      });
      break;
    case "UPDATE_CALL_GROUP_LIST_FAILURE":
      newState = Object.assign({}, previousState, {
        data: undefined,
        pages: 0,
        totalElements: 0,
        loading: false,
        isError: true,
      });
      break;
    case "RESET_ERROR_MESSAGE_CALL_GROUP_LIST":
      newState = Object.assign({}, previousState, {
        isError: false,
      });
      break;
    case "UPDATE_CALL_GROUP_CLAIMER":
      newState = Object.assign({}, previousState, {
        data: action.payload.data,
      });
      break;

    case "UPDATE_BIO_SIMILAR_TOGGLE":
      newState = Object.assign({}, previousState , {
        isShowBioSimilar: action.payload
      })

    default:
      break;
  }

  return newState;
}
