import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  Col,
  Row,
  Container,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import ServerSideReactTable from "commons/table/ServerSideReactTable";
import {
  DEFAULT_BULK_LIST_SORTED,
  DEFAULT_BULK_LIST_FILTERS,
  bulk_action_statuses,
  bulk_action_type_options,
  DEFAULT_PAGE_SIZE,
} from "lookup/BulkActionListConstants";
import { updateBulkActionList } from "./BulkList.actions";
import { useSelector, useDispatch } from "react-redux";
import { ColumnTypes } from "commons/table/Constants";
import { FilterTypes } from "commons/table/Constants";
import { WrapTextAndCenterAlign } from "components/WrapText";
import { formatDateWithTime } from "services/MomentUtils";
import ResetListState from "components/ResetListState";
import ReactBadge from "react-bootstrap/Badge";
import { useLocation } from "react-router-dom";
import { MdModeEdit, MdVisibility, MdSync } from "react-icons/md";
import moment from "moment";

const spaceyStyle = {
  letterSpacing: "0.6px",
};

const Badge = (props) => (
  <div>
    <ReactBadge {...props}>{props.children}</ReactBadge>
  </div>
);

const BulkList = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const pages = useSelector((state) =>
    state.bulkList.pages !== undefined ? state.bulkList.pages : 0
  );
  const totalElements = useSelector((state) =>
    state.bulkList.totalElements !== undefined
      ? state.bulkList.totalElements
      : 0
  );
  const listState = useSelector((state) =>
    state.bulkList.listState !== undefined
      ? state.bulkList.listState
      : {
          page: 0,
          pageSize: DEFAULT_PAGE_SIZE,
          sorted: DEFAULT_BULK_LIST_SORTED,
          filtered: DEFAULT_BULK_LIST_FILTERS,
          resized: [],
          expanded: {},
        }
  );
  const data = useSelector((state) =>
    state.bulkList.data !== undefined ? state.bulkList.data : []
  );
  const loading = useSelector((state) => state.bulkList.loading);
  const updateData = (state) => {
    if (state.sorted && state.sorted !== DEFAULT_BULK_LIST_SORTED)
      state.sorted.forEach((element, i) => {
        element.isDefault = false;
      });
    const filteredForStatus = state.filtered.filter(
      (element) =>
        !(element.id === "status" && element.value.includes("ALL_FILTERS"))
    );
    const allBulkActionActionType = filteredForStatus.findIndex(
      (element) => element.id === "bulkActionType" && element.value === "All"
    );
    if (allBulkActionActionType !== -1) {
      state.filtered[allBulkActionActionType].value = bulk_action_type_options
        .filter((option) => option.value !== "All")
        .map((option) => option.value);
    }

    if (filteredForStatus.length !== state.filtered.length) {
      state.filtered = filteredForStatus;
    }
    dispatch(
      updateBulkActionList(
        state.pageSize,
        state.page,
        state.sorted,
        state.filtered,
        state.resized,
        state.expanded
      )
    );
  };
  const onUpdateTable = (filterValues, sortBy, pageSize, pageNo) => {
    listState.pageSize = pageSize;
    listState.page = pageNo;
    listState.sorted = sortBy ? sortBy : [];
    listState.filtered = filterValues;
    updateData(listState);
  };

  useEffect(() => {
    updateData(listState);
  }, [location]);

  const renderRowDescription = (rowData) => {
    return (
      <span style={{ display: "flex" }}>
        <span style={{ flex: "9" }}>{rowData.description}</span>
      </span>
    );
  };

  const renderRowId = (rowData) => {
    return (
      <span style={{ display: "flex" }}>
        <span style={{ flex: "9" }}>{rowData.bulkActionId}</span>
      </span>
    );
  };

  const renderAllClients = (clients) => {
    if (!clients || clients.length === 0) {
      return <span></span>;
    }

    const displayLimit = 3;

    return (
      <span
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          rowGap: "10px",
        }}
      >
        {clients.slice(0, displayLimit).map((client, index) => (
          <Badge key={index} variant="primary" pill style={spaceyStyle}>
            {client}
          </Badge>
        ))}
        {clients.length > displayLimit && (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-top`}>
                {clients.slice(displayLimit).join(", ")}
              </Tooltip>
            }
          >
            <span
              className="badge badge-pill"
              style={{
                marginTop: "2px",
                fontSize: "11px",
                borderRadius: 20,
                color: "white",
                fontWeight: "bolder",
                backgroundColor: "gray",
              }}
              variant="primary"
            >
              {" "}
              +{clients.length - displayLimit} more
            </span>
          </OverlayTrigger>
        )}
      </span>
    );
  };

  const renderDateCell = (date) => {
    return <span>{formatDateWithTime(date)}</span>;
  };
  const handleRefresh = () => {
    updateData(listState);
  };
  const renderActions = (rowData) => {
    return (
      <span className="action-button">
        <span style={{ flex: "9" }}>{rowData.bulkActionType}</span>
      </span>
    );
  };

  const RoutableColumnForParentId = ({ link, value, bulkActionParentUuid }) => {
    const rowInfo = data.filter(
      (row) => row.bulkActionUuid === bulkActionParentUuid
    )[0];
    const handleColumnClick = (event) => {
      event.stopPropagation(); // This stops the event from bubbling up to the row
      props.history.push({
        pathname: `${link}`,
        state: {
          bulkActionType: rowInfo.bulkActionType,
          bulkActionScheduledDate: rowInfo.dateScheduled,
          clientList: rowInfo.clientNames,
          customerName: rowInfo.customerName,
          description: rowInfo.description,
          noOfSwitches: rowInfo.numberOfSwitches,
          note: rowInfo.note,
          isEditable: rowInfo.status === "SCHEDULED",
          isViewable:
            rowInfo.status === "COMPLETED" ||
            rowInfo.status === "ACTIVE" ||
            rowInfo.status === "CANCELLED",
          showCancel: rowInfo.status === "SCHEDULED",
        },
      });
    };

    return (
      <a href={link} onClick={handleColumnClick}>
        {value}
      </a>
    );
  };

  const [columns, setColumns] = useState([
    {
      header: "Actions",
      accessor: "actionsAllowed",
      width: 10,
      columnType: ColumnTypes.Custom,
      customComponent: (value, row) => {
        if (
          row.original.status === "COMPLETED" ||
          (row.original.status === "SCHEDULED" &&
            moment(row.original.dateScheduled).diff(moment(), "minutes") > 10)
        ) {
          return (
            <OverlayTrigger
              overlay={<Tooltip>Edit Current Bulk Action</Tooltip>}
            >
              <MdModeEdit
                size={15}
                className="user-action-button edit-user"
                onClick={(event) => {
                  event.stopPropagation(); // Prevent triggering any row-level event
                  props.history.push({
                    pathname: `/scheduler/bulk-action/${row.original.bulkActionUuid}`, // Assuming you have bulkActionUuid in your row data
                    state: {
                      bulkActionType: row.original.bulkActionType,
                      bulkActionScheduledDate: row.original.dateScheduled,
                      clientList: row.original.clientNames,
                      customerName: row.original.customerName,
                      description: row.original.description,
                      noOfSwitches: row.original.noOfSwitches,
                      note: row.original.note,
                      isEditable: true,
                      isCompleted: row.original.status === "COMPLETED",
                      isViewable: false,
                      showCancel: row.original.status === "SCHEDULED",
                      showBioSimilarToggle: false,
                    },
                  });
                }}
              />
            </OverlayTrigger>
          );
        } else {
          return (
            <OverlayTrigger
              overlay={<Tooltip>View Current Bulk Action</Tooltip>}
            >
              <MdVisibility
                size={15}
                className="user-action-button edit-user"
                onClick={(event) => {
                  event.stopPropagation(); // Prevent triggering any row-level event
                  props.history.push({
                    pathname: `/scheduler/bulk-action/${row.original.bulkActionUuid}`, // Assuming you have bulkActionUuid in your row data
                    state: {
                      bulkActionType: row.original.bulkActionType,
                      bulkActionScheduledDate: row.original.dateScheduled,
                      clientList: row.original.clientNames,
                      customerName: row.original.customerName,
                      description: row.original.description,
                      noOfSwitches: row.original.noOfSwitches,
                      note: row.original.note,
                      isEditable: false,
                      isViewable: true,
                      showBioSimilarToggle: false,
                    },
                  });
                }}
              />
            </OverlayTrigger>
          );
        }
      },
    },
    {
      header: "Scheduled/Execution Date",
      accessor: "dateScheduled",
      accessorKey: "dateScheduled",
      enableSorting: true,
      width: 110,
      enableColumnFilter: false,
      columnType: ColumnTypes.Custom,
      customComponent: (value) => {
        return renderDateCell(value);
      },
    },

    {
      header: "Description",
      accessor: "description",
      accessorKey: "description",
      enableSorting: false,
      width: 110,
      enableColumnFilter: true,
      columnType: ColumnTypes.Custom,
      filterType: FilterTypes.SearchableInput,
      customComponent: (value, row) => {
        return renderRowDescription(row.original);
      },
    },

    {
      header: "Customer",
      accessor: "customerName",
      accessorKey: "customerName",
      enableSorting: false,
      enableColumnFilter: false,
      width: 50,
      columnType: ColumnTypes.Custom,
      customComponent: (value) => {
        return renderAllClients(value);
      },
    },
    {
      header: "Clients",
      accessor: "clientNames",
      accessorKey: "clientNames",
      enableSorting: false,
      enableColumnFilter: false,
      width: 70,
      columnType: ColumnTypes.Custom,
      customComponent: (value) => {
        return renderAllClients(value);
      },
    },
    {
      header: "Bulk Action Id #",
      accessor: "bulkActionId",
      accessorKey: "bulkActionId",
      enableSorting: true,
      width: 50,
      enableColumnFilter: true,
      columnType: ColumnTypes.Custom,
      filterType: FilterTypes.SearchableInput,
      customComponent: (value, row) => {
        return renderRowId(row.original);
      },
    },
    {
      header: "Total Switches",
      accessor: "numberOfSwitches",
      accessorKey: "numberOfSwitches",
      enableSorting: false,
      width: 50,
      enableColumnFilter: false,
      columnType: ColumnTypes.Custom,
      customComponent: (value) => {
        return <WrapTextAndCenterAlign>{value}</WrapTextAndCenterAlign>;
      },
    },
    {
      header: "Action Performed",
      accessor: "bulkActionType",
      accessorKey: "bulkActionType",
      enableSorting: false,
      enableFiltering: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.Custom,
      filterType: FilterTypes.SingleSelect,
      filterOptions: bulk_action_type_options,
      customComponent: (value, row) => {
        return renderActions(row.original);
      },
    },
    {
      header: "Status of Action Performed",
      accessor: "status",
      accessorKey: "status",
      width: 110,
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.Custom,
      filterType: FilterTypes.SingleSelect,
      filterOptions: bulk_action_statuses,
      customComponent: (value) => {
        return <WrapTextAndCenterAlign>{value}</WrapTextAndCenterAlign>;
      },
    },
  ]);

  useEffect(() => {
    if (data !== undefined && data.length > 0 && !loading) {
      setColumns((prevState) => {
        const existingIndex = prevState.findIndex(
          (col) => col.accessor === "bulkActionParentId"
        );
        const newColumn = {
          header: "Parent Id",
          accessor: "bulkActionParentId",
          accessorKey: "bulkActionParentId",
          enableSorting: true,
          width: 20,
          enableColumnFilter: true,
          columnType: ColumnTypes.Custom,
          filterType: FilterTypes.SearchableInput,
          customComponent: (value, row) => {
            if (row.original.bulkActionParentUuid !== null) {
              return (
                <WrapTextAndCenterAlign>
                  <RoutableColumnForParentId
                    link={`/scheduler/bulk-action/${row.original.bulkActionParentUuid}`}
                    bulkActionParentUuid={row.original.bulkActionParentUuid}
                    value={value}
                  />
                </WrapTextAndCenterAlign>
              );
            }
            return <WrapTextAndCenterAlign>{value}</WrapTextAndCenterAlign>;
          },
        };
        if (existingIndex >= 0) {
          let newColumns = [...prevState];
          newColumns[existingIndex] = newColumn;
          return newColumns;
        } else {
          return [...prevState, newColumn];
        }
      });
    }
  }, [data, loading]);

  return (
    <Container className="list-container" fluid>
      <Row>
        <Col md={12}>
          <div className="codex-version-display-bar">
            <span style={{ float: "left" }}>
              {" "}
              Total Bulk Actions: <strong>{totalElements}</strong>
            </span>
            <strong style={{ float: "center" }}>Bulk Action List</strong>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="refresh-tooltip">Refresh List</Tooltip>}
            >
              <Button
                variant="primary"
                style={{ float: "right", marginLeft: "10px" }}
                onClick={handleRefresh}
              >
                <MdSync />
              </Button>
            </OverlayTrigger>
            <ResetListState
              onReset={() =>
                updateData({
                  ...listState,
                  sorted: DEFAULT_BULK_LIST_SORTED,
                  filtered: DEFAULT_BULK_LIST_FILTERS,
                })
              }
              resetterDisabled={
                listState.sorted === DEFAULT_BULK_LIST_SORTED &&
                listState.filtered === DEFAULT_BULK_LIST_FILTERS
              }
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ServerSideReactTable
            columnDefination={columns}
            data={data}
            currentPage={listState.page}
            sorted={listState.sorted}
            filters={listState.filtered}
            totalPages={pages}
            totalRecords={totalElements == "loading" ? 0 : totalElements}
            loading={loading}
            enableMultiRowSelection={false}
            addRowSelection={false}
            defaultPageSize={DEFAULT_PAGE_SIZE}
            pageSizeOptions={[5, 10, 15, 20, 30, 50]}
            onUpdateTableData={onUpdateTable}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(BulkList);
