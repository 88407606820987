import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";
import { scheduleBatch, resetSuccess } from "./BatchScheduler.action";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import Container from "react-bootstrap/Container";
import DateTimePicker from "react-datetime-picker";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormLabel from "react-bootstrap/FormLabel";
import FormGroup from "react-bootstrap/FormGroup";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Alert from "react-bootstrap/Alert";

import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import ReactBadge from "react-bootstrap/Badge";
import { format } from "date-fns";
import Select from "react-select";
import { Form } from "react-bootstrap";
import { showNotification } from "services/NotificationService";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import MessageBanner from "../../../components/MessageBanner.js";
import { isLoggedInUserBioSimilarUser } from "services/AWUtilityService";

const Tip = (props) => (
  <div style={{ textAlign: "right", color: "#000", fontSize: "0.9em" }}>
    <em>{props.children}</em>
  </div>
);

const customStyles = {
  multiValue: (base) => ({
    background: "#007BBB",
    display: "inline-flex",
    color: "white",
    borderRadius: "11px",
    alignItems: "center",
  }),
  multiValueLabel: (base) => ({
    fontSize: "13px",
    color: "white",
    padding: "2px 4px 2px 4px",
  }),
};

class BatchScheduler extends React.Component {
  state = {
    description: "",
    dateTime: new Date(),
    switchDateRange: new Date(),
    allClientsList: [],
    clientOptions: [],
    createAllActivities: true,
    error: "",
    submittingRequest: "",
    isSuccess: false,
    disableAction: false,
    eventRequestCompleted: false,
    noOfSelectedSwitches: 0,
    activityCount: 0,
    selectedFilters: {
      clientNameAbbreviation: [],
      customerNameAbbreviation: [],
    },
    filterSelectMap: {},
    originalFilterState: {},
    isShowBioSimilarToggle: false,
  };

  setBioSimilarSwitchesToggle = (value) => {
    this.setState({ isShowBioSimilarToggle: value });
  };

  componentDidMount() {
    this.fetchToCheckIfCycleIsOpen();
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    const {
      description,
      switchDateRange,
      error,
      submittingRequest,
      isSuccess,
      disableAction,
      eventRequestCompleted,
      noOfSelectedSwitches,
    } = this.props;

    if (
      prevProps !== null &&
      (prevProps.description !== description ||
        prevProps.switchDateRange !== switchDateRange ||
        prevProps.error !== error ||
        prevProps.submittingRequest !== submittingRequest ||
        prevProps.isSuccess !== isSuccess ||
        prevProps.disableAction !== disableAction ||
        prevProps.eventRequestCompleted !== eventRequestCompleted ||
        prevProps.noOfSelectedSwitches !== noOfSelectedSwitches)
    ) {
      this.setState({
        description,
        switchDateRange,
        error,
        submittingRequest,
        isSuccess,
        disableAction,
        eventRequestCompleted,
        noOfSelectedSwitches,
      });

      if (isSuccess) {
        showNotification({
          title: "Batch Created!",
          message: "Batch is scheduled successfully.",
          position: "bl",
          type: "success",
        });
        this.props.resetSuccess();
        this.props.history.push("/scheduler/batch/list");
      }
    }
  }

  fetchToCheckIfCycleIsOpen() {
    sendAuthenticatedAsyncRequest(`/swim/open-batch-exists`, "GET", null, (r) =>
      this.setState({ disableAction: r.data })
    );
  }

  fetchData() {
    sendAuthenticatedAsyncRequest(
      `/swim/batch/get-client-customer-filters`,
      "GET",
      null,
      (r) => {
        if (r.data.filters !== null) {
          this.updateFilters(r.data.filters);
        }
      },
      (r) => {
        showNotification({
          title: "Error fetching Filter Values!",
          message: "Something went Wrong.",
          position: "tr",
          type: "error",
        });
      }
    );
  }

  fetchNoofSelectedSwitches(
    clients,
    startDate,
    endDate,
    isShowBioSimilarToggle
  ) {
    let url = `/swim/get-selected-switches-count?date_scheduled=${
      this.state.dateTime
        ? format(this.state.dateTime, "yyyy-MM-dd HH:mm:ss")
        : format(new Date(), "yyyy-MM-dd HH:mm:ss")
    }`;
    url = url.concat(
      clients && !clients.includes("00000000-0000-0000-0000-000000000000")
        ? `&client_list=${clients}`
        : ""
    );
    url = url.concat(
      startDate
        ? `&start_date=${format(
            startDate,
            "yyyy-MM-dd HH:mm:ss"
          )}&end_date=${format(endDate, "yyyy-MM-dd HH:mm:ss")}`
        : ""
    );
    url = url.concat(`&show_bio_similar=${isShowBioSimilarToggle}`);
    sendAuthenticatedAsyncRequest(url, "GET", null, (r) =>
      this.setState({ noOfSelectedSwitches: r.data })
    );
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value, error: "" });
  };

  handleCreateAllActivitiesChange = (e) => {
    const { checked } = e.target;
    this.setState({ createAllActivities: checked });
    if (checked) {
      this.setState({ activityCount: 0 });
    }
  };

  validateForm(dateTime, clientList) {
    //Validate if the date for scheduling cycle should be greater than current date
    var currentDate = new Date();
    if (!dateTime) return "You need to select Date & Time";
    if (dateTime < currentDate)
      return "Date and Time should be greater then current Time";
    if (!clientList || clientList.length < 1)
      return "You need to select at least one client";
    return null;
  }

  addClientsToCustomers(
    customerListArray = this.state.selectedFilters.customerNameAbbreviation,
    clientListArray = this.state.selectedFilters.clientNameAbbreviation
  ) {
    let extractedSelectedClients = clientListArray;

    if (clientListArray.length === 0) {
      const appliedCustomerFilters = customerListArray.map(
        (customer) => customer.label
      );
      const extractedSelectedCustomers = this.state.filterSelectMap[
        "customerClientMapping"
      ].filter((e) => appliedCustomerFilters.includes(e.value));
      const extractedClients = extractedSelectedCustomers.reduce(
        (result, entry) => {
          return result.concat(entry.label);
        },
        []
      );

      extractedSelectedClients =
        this.state.originalFilterState.clientNameAbbreviation.filter((item) =>
          new Set(extractedClients).has(item.label)
        );
    }

    return extractedSelectedClients.map((client) => client.value);
  }

  scheduleBatch = (e) => {
    const {
      dateTime,
      description,
      switchDateRange,
      createAllActivities,
      activityCount,
    } = this.state;
    const tempClientList = this.addClientsToCustomers();
    var validationMessage = this.validateForm(dateTime, tempClientList);
    if (validationMessage === null) {
      const batchDto = {
        scheduledDate: dateTime,
        description: description,
        clientList: tempClientList,
        switchCreationDateRange: Array.isArray(switchDateRange)
          ? switchDateRange
          : null,
        createAllActivities: createAllActivities,
        activityCount: activityCount,
      };
      this.props.scheduleBatch(batchDto);
    } else {
      this.setState({ error: validationMessage });
    }
  };

  onDateChange = (dateTime) => {
    this.setState({ dateTime, error: "" });
    this.fetchNoofSelectedSwitches(
      this.addClientsToCustomers(),
      this.state.switchDateRange ? this.state.switchDateRange[0] : null,
      this.state.switchDateRange ? this.state.switchDateRange[1] : null,
      this.state.isShowBioSimilarToggle
    );
  };

  onDateRangeChange = (switchDateRange) => {
    this.setState({ switchDateRange, error: "" });
    this.fetchNoofSelectedSwitches(
      this.addClientsToCustomers(),
      switchDateRange ? switchDateRange[0] : null,
      switchDateRange ? switchDateRange[1] : null,
      this.state.isShowBioSimilarToggle
    );
  };

  handleToggleChangeForBioSimilar = (e) => {
    if (e.target.checked) {
      this.setBioSimilarSwitchesToggle(true);
      this.fetchNoofSelectedSwitches(
        this.addClientsToCustomers(),
        this.state.switchDateRange ? this.state.switchDateRange[0] : null,
        this.state.switchDateRange ? this.state.switchDateRange[1] : null,
        true
      );
    } else {
      this.setBioSimilarSwitchesToggle(false);
      this.fetchNoofSelectedSwitches(
        this.addClientsToCustomers(),
        this.state.switchDateRange ? this.state.switchDateRange[0] : null,
        this.state.switchDateRange ? this.state.switchDateRange[1] : null,
        false
      );
    }
  };

  updateFilters(tempFilters) {
    let tempFilterSelectMap = {};
    Object.keys(tempFilters).forEach(
      (colKey, i) =>
        (tempFilterSelectMap[colKey] = tempFilters[colKey].map((row, index) => {
          return { value: row["id"], label: row["value"] };
        }))
    );
    this.setState({ originalFilterState: tempFilterSelectMap });
    this.setState({ filterSelectMap: tempFilterSelectMap });
  }

  handleCustomerChange = (option) => {
    let extractedSelectedClients = [];
    if (option !== null) {
      const appliedCustomerFilters = option.label;
      const extractedSelectedCustomers = this.state.filterSelectMap[
        "customerClientMapping"
      ].filter((e) => appliedCustomerFilters === e.value);
      const extractedClients = extractedSelectedCustomers.reduce(
        (result, entry) => {
          return result.concat(entry.label);
        },
        []
      );

      extractedSelectedClients =
        this.state.selectedFilters.clientNameAbbreviation.filter((item) =>
          new Set(extractedClients).has(item.label)
        );
      const clientOptions = this.state.originalFilterState[
        "clientNameAbbreviation"
      ].filter((e) => extractedClients.includes(e.label));
      this.setState((prevState) => ({
        filterSelectMap: {
          ...prevState.filterSelectMap,
          clientNameAbbreviation: clientOptions,
        },
      }));
    }

    // Set customer and client lists
    const updatedCustomerList = option !== null ? [option] : [];
    this.setState({
      selectedFilters: {
        clientNameAbbreviation: extractedSelectedClients,
        customerNameAbbreviation: updatedCustomerList,
      },
    });

    // Fetch available switches
    const tempClientList = this.addClientsToCustomers(
      updatedCustomerList,
      extractedSelectedClients
    );
    if (
      !(
        updatedCustomerList &&
        updatedCustomerList.length === 1 &&
        updatedCustomerList[0].label === "WMT"
      )
    ) {
      if (this.state.isShowBioSimilarToggle) {
        this.setBioSimilarSwitchesToggle(!this.state.isShowBioSimilarToggle);
      }
    }

    this.fetchNoofSelectedSwitches(
      tempClientList,
      this.state.switchDateRange ? this.state.switchDateRange[0] : null,
      this.state.switchDateRange ? this.state.switchDateRange[1] : null,
      this.state.isShowBioSimilarToggle
    );
  };

  handleClientChange = (option) => {
    let clientListArray = option !== null ? option : [];
    this.setState((prevState) => ({
      selectedFilters: {
        ...prevState.selectedFilters,
        clientNameAbbreviation: clientListArray,
      },
    }));

    const tempClientList = this.addClientsToCustomers(
      this.state.selectedFilters.customerNameAbbreviation,
      clientListArray
    );

    this.fetchNoofSelectedSwitches(
      tempClientList,
      this.state.switchDateRange ? this.state.switchDateRange[0] : null,
      this.state.switchDateRange ? this.state.switchDateRange[1] : null,
      this.state.isShowBioSimilarToggle
    );
  };

  handleActivityCountChange = (e) => {
    this.setState({ createAllActivities: !e.target.value });
  };

  handleNumberInputChange = (e) => {
    this.setState({ activityCount: e.target.value });
  };

  render() {
    const {
      dateTime,
      description,
      error,
      submittingRequest,
      disableAction,
      eventRequestCompleted,
      noOfSelectedSwitches,
      filterSelectMap,
      selectedFilters,
    } = this.state;
    if (eventRequestCompleted) return <Redirect to={`/batch/list`} />;
    return (
      <Container fluid className="top-spacer">
        <Row>
          <Col md={{ span: 15 }}>
            <MessageBanner
              message={
                "You cannot schedule a new Batch as one is already being processed"
              }
              isDisplayMessage={disableAction}
              icon={faExclamationCircle}
            />
            <div>
              <Card variant="primary">
                <Card.Header>
                  <Card.Title className="text-center">
                    <h4>Scheduler</h4>
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  {error ? <Alert variant="danger">{error}</Alert> : null}
                  {submittingRequest ? (
                    <Alert variant="primary">{submittingRequest}</Alert>
                  ) : null}
                  <Row>
                    <Col md={12}>
                      <Tip>Enter Date & Time according to your Time Zone</Tip>
                    </Col>
                    <Col md={12}>
                      <FormGroup as={Row}>
                        <Col md={3}>
                          <FormLabel>Date & Time</FormLabel>
                        </Col>
                        <Col md={9}>
                          <DateTimePicker
                            onChange={this.onDateChange}
                            value={dateTime}
                            disabled={disableAction}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="tiny-top-spacer" as={Row}>
                        <Col md={3}>
                          <FormLabel>Description </FormLabel>
                        </Col>
                        <Col md={9}>
                          <FormControl
                            type="text"
                            name="description"
                            value={description}
                            placeholder="Enter description (Optional)"
                            onChange={this.handleChange}
                            disabled={disableAction}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup as={Row}>
                        <Col md={3}>
                          <FormLabel>Customer </FormLabel>
                        </Col>
                        <Col md={9}>
                          <Select
                            options={
                              Object.keys(filterSelectMap).includes(
                                "customerNameAbbreviation"
                              )
                                ? filterSelectMap["customerNameAbbreviation"]
                                : []
                            }
                            value={selectedFilters.customerNameAbbreviation}
                            onChange={(event) =>
                              this.handleCustomerChange(event)
                            }
                            placeholder={
                              selectedFilters.clientNameAbbreviation.length ===
                              0
                                ? "Enter Customer (Mandatory)"
                                : "Enter Customer (Optional)"
                            }
                            isDisabled={disableAction}
                            styles={customStyles}
                            closeMenuOnSelect={false}
                          ></Select>
                        </Col>
                      </FormGroup>
                      <FormGroup className="tiny-top-spacer" as={Row}>
                        <Col md={3}>
                          <FormLabel>Client </FormLabel>
                        </Col>
                        <Col md={9}>
                          <Select
                            options={
                              Object.keys(filterSelectMap).includes(
                                "clientNameAbbreviation"
                              )
                                ? filterSelectMap["clientNameAbbreviation"]
                                : []
                            }
                            onChange={(event) => this.handleClientChange(event)}
                            value={selectedFilters.clientNameAbbreviation}
                            placeholder={
                              selectedFilters.customerNameAbbreviation
                                .length === 0
                                ? "Enter Client (Mandatory)"
                                : "Enter Client (Optional)"
                            }
                            isMulti
                            isDisabled={disableAction}
                            styles={customStyles}
                            closeMenuOnSelect={false}
                          ></Select>
                        </Col>
                      </FormGroup>
                      <FormGroup className="tiny-top-spacer" as={Row}>
                        <Col md={3}>
                          <FormLabel>Switch Creation Date Range </FormLabel>
                        </Col>
                        <Col md={9}>
                          <DateRangePicker
                            block
                            style={{ position: "relative", zIndex: "0" }}
                            name="switchCreationDateRange"
                            //value={switchDateRange}
                            onChange={this.onDateRangeChange}
                            format="yyyy-MM-dd"
                            placeholder="Enter Date Range (Optional)"
                            disabled={disableAction}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="tiny-top-spacer" as={Row}>
                        <Col md={3}>
                          <FormLabel>No. of Selected Switches </FormLabel>
                        </Col>
                        <Col md={9}>
                          <ReactBadge
                            id="no-of-selected-switches"
                            variant="success"
                            pill
                            style={{ margin: "-1px 5px", fontSize: "100%" }}
                          >
                            {noOfSelectedSwitches ? noOfSelectedSwitches : 0}
                          </ReactBadge>
                        </Col>
                      </FormGroup>
                      <FormGroup as={Row} className="align-items-center">
                        {/* Row for radio buttons, activity count, and toggle */}
                        <Col md={12}>
                          <Row className="align-items-center">
                            <Col md="auto">
                              <Form.Check
                                type="radio"
                                name="activityFiltering"
                                id="batch-scheduler-create-all-activities"
                                label="Create All Activities"
                                onChange={this.handleCreateAllActivitiesChange}
                                style={{ zIndex: "0" }}
                                defaultChecked={true}
                                disabled={disableAction}
                                className="mr-3"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md="auto">
                              <Form.Check
                                type="radio"
                                name="activityFiltering"
                                id="batch-scheduler-activity-count"
                                label="Activity Count:"
                                onChange={this.handleActivityCountChange}
                                style={{ zIndex: "0" }}
                                disabled={disableAction}
                                className="mr-2"
                              />
                            </Col>
                            <Col md="auto">
                              <Form.Control
                                type="number"
                                value={this.state.activityCount}
                                onChange={this.handleNumberInputChange}
                                disabled={this.state.createAllActivities}
                                style={{ width: "80px" }}
                                className="mr-3"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md="auto">
                              {(selectedFilters.clientNameAbbreviation.some(
                                (client) => client.label === "WMT"
                              ) ||
                                selectedFilters.customerNameAbbreviation.some(
                                  (customer) => customer.label === "WMT"
                                )) &&
                                isLoggedInUserBioSimilarUser() && (
                                  <Form.Check
                                    type="switch"
                                    id="bioSimilarSwitchesToggle"
                                    label="Bio Similar Switches"
                                    onChange={
                                      this.handleToggleChangeForBioSimilar
                                    }
                                    checked={this.state.isShowBioSimilarToggle}
                                    className="mr-3"
                                  />
                                )}
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          md={12}
                          className="d-flex justify-content-end mt-3"
                        >
                          <Button
                            style={{
                              paddingLeft: "50px",
                              paddingRight: "50px",
                            }}
                            disabled={
                              disableAction || noOfSelectedSwitches === 0
                            }
                            onClick={this.scheduleBatch}
                          >
                            Schedule Batch
                          </Button>
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    dateTime: state.batchScheduler.dateTime,
    error: state.batchScheduler.error,
    description: state.batchScheduler.description,
    submittingRequest: state.batchScheduler.submittingRequest,
    isSuccess: state.batchScheduler.isSuccess,
    disableAction: state.batchScheduler.disableAction,
    eventRequestCompleted: state.batchScheduler.eventRequestCompleted,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    scheduleBatch: bindActionCreators(scheduleBatch, dispatch),
    resetSuccess: bindActionCreators(resetSuccess, dispatch),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BatchScheduler)
);
